import Experience from '../Experience.js'
import { gsap, CustomEase } from 'gsap/all'
import Split from '../Utils/Split.js'
import Error from './Error.js'
import Loader from './Loader.js'
import Home from './Home.js'
import Artist from './Artist.js'
import artistsContent from '../artistsContent.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.router = this.experience.router
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeOutCubic', '0.33, 1, 0.68, 1')
        CustomEase.create('easeInOutCubic', '0.65, 0, 0.35, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        this.linesSplitted = false
        this.timer = null
        this.location = window.location.pathname
        this.isArtist = false
        this.loadOnArtist = false
        this.isMenuOpen = false
        this.opacityMenu = 0
        this.textYGOF = 100
        this.textYGOF2 = 100
        this.textYBack = 100
        this.textYMenu = 100
        this.textLinesYMenu = -100
        this.textLinesYMenuValue = -100

        document.querySelector('nav .menu-btn').addEventListener('click', () =>
        {
            this.isMenuOpen = !this.isMenuOpen
            this.home.isMenuOpen = this.isMenuOpen
            this.artist.isMenuOpen = this.isMenuOpen
        })

        // Load
        this.load = () => 
        {
            artistsContent.forEach((artist, index) => 
            {
                if(window.location.pathname === "/" + artist.url) 
                {
                    this.error = null
                    document.querySelector('.p404').remove()
                    this.loader.intro()
                    this.home.selectedArtistIndex = index
                    this.artist.artistIndex = index
                    this.loadOnArtist = true
                    this.home.loadOnArtist = true
                }
            })

            if(!this.loadOnArtist)
            {
                if(window.location.pathname === "/")
                {
                    this.error = null
                    document.querySelector('.p404').remove()
                    this.loader.intro()
                }
                else
                {

                    this.loader = null
                    this.home = null
                    this.artist = null
                    document.querySelector('.loader').remove()
                    document.querySelector('nav').remove()
                    document.querySelector('.menu').remove()
                    document.querySelector('.artist').remove()
                    this.error.intro()
                }
            }
        }

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.error = new Error()

            this.loader = new Loader()

            this.home = new Home()

            this.artist = new Artist()

            this.load()
        })
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    resize()
    {
        document.querySelector('.menu .column:first-of-type').innerHTML = '<p>Gallery of fame is a French initiative imagined by Dorian Cottin and Timothée Berger. This virtual art gallery lists what we believe to be the most influential people in their field. Our desire is to highlight the artists and designers who have shaped our current world thanks to their disruptive vision.</p><p>Our project stems from a common desire and this is instructive for anyone wishing to learn about these areas. This gallery is subject to change.</p>'
        this.linesSplitted = false

        this.home.isResizing = true
        this.home.resize()

        this.artist.isResizing = true
        this.artist.resize()

        if(this.timer !== null) {
            clearTimeout(this.timer)      
        }

        this.timer = setTimeout(() => {
            this.home.isResizing = false

            this.artist.isResizing = false
        }, 10)
    }

    update()
    {
        if(!this.error && this.loader && this.loader.isIntroDone && document.querySelector('.menu .column:first-of-type').offsetTop !== 0 && !this.linesSplitted)
        {
            this.linesSplitted = true

            Split('lines', '.menu p')
            Split('lines', '.menu a')
        }

        if(this.error)
        {
            this.error.update()
        }

        if(this.loader)
        {
            this.loader.update()

            if(this.loader.isIntroDone && !this.home.isIntroStart)
            {
                this.home.intro()
            }
        }

        if(this.home)
        {
            this.home.update()

            if(this.home.isIntroDone && !this.loadOnArtist)
            {
                this.loader.isHomeIntroDone = true

                if(this.isMenuOpen)
                {
                    gsap.set('.menu', {
                        display: 'inline'
                    })
                    if(this.isArtist)
                    {
                        this.textYGOF = this.lerp(this.textYGOF, -100, .1)
                        this.textYGOF2 = this.lerp(this.textYGOF2, 0, .1)
                        this.textYBack = this.lerp(this.textYBack, -100, .1)
                    }
                    else 
                    {
                        this.textYGOF = this.lerp(this.textYGOF, -100, .1)
                        this.textYGOF2 = this.lerp(this.textYGOF2, 0, .1)
                        this.textYBack = this.lerp(this.textYBack, 100, .1)
                    }
                    this.textYMenu = this.lerp(this.textYMenu, -100, .1)
                    this.opacityMenu = this.lerp(this.opacityMenu, 1, .1)
                    this.textLinesYMenu = this.lerp(this.textLinesYMenu, 0, .1)
                }
                else
                {
                    if(this.isArtist)
                    {
                        this.textYGOF = this.lerp(this.textYGOF, -100, .1)
                        this.textYGOF2 = this.lerp(this.textYGOF2, 100, .1)
                        this.textYBack = this.lerp(this.textYBack, 0, .1)
                    }
                    else 
                    {
                        this.textYGOF = this.lerp(this.textYGOF, 0, .1)
                        this.textYGOF2 = this.lerp(this.textYGOF2, 100, .1)
                        this.textYBack = this.lerp(this.textYBack, 100, .1)
                    }
                    this.textYMenu = this.lerp(this.textYMenu, 0, .1)
                    this.opacityMenu = this.lerp(this.opacityMenu, 0, .1)
                    this.textLinesYMenu = this.lerp(this.textLinesYMenu, -100, .1)

                    if(this.opacityMenu <= .001)
                    {
                        gsap.set('.menu', {
                            display: 'none'
                        })
                    }
                }

                gsap.set('.menu', {
                    opacity: this.opacityMenu
                })

                gsap.set('nav a span:first-of-type', {
                    y: `${this.textYGOF}%`,
                    force3D: true
                })

                gsap.set('nav a span:nth-of-type(2)', {
                    y: `${this.textYBack}%`,
                    force3D: true
                })

                gsap.set('nav a span:last-of-type', {
                    y: `${this.textYGOF2}%`,
                    force3D: true
                })

                gsap.set('nav .menu-btn span', {
                    y: `${this.textYMenu}%`,
                    force3D: true
                })

                gsap.set('.menu .word', {
                    y: `${this.textLinesYMenu}%`,
                    force3D: true
                })
            } 
            else if(this.home.isIntroDone && this.loadOnArtist)
            {
                if(this.loadOnArtist)
                {
                    this.home.artistTransition()
                    this.loadOnArtist = false
                    this.home.loadOnArtist = false
                }
            }

            if(this.home.raycaster.currentIntersect && !this.home.isTransitioning && this.home.selectedArtistIndex !== this.artist.artistIndex && !this.artist.isArtistSet)
            {
                this.artist.artistIndex = this.home.selectedArtistIndex
            }

            if(this.home.isTransitioning && !this.artist.isArtistSet)
            {
                this.artist.setHtml()
            }

            if(this.home.isArtistTransitionDone && !this.artist.isArtistSet)
            {
                this.artist.setArtist()
                this.isArtist = true
            }

            if(this.home.isIntroDone && window.location.pathname !== this.location)
            {
                artistsContent.forEach((artist, index) => 
                {
                    if(window.location.pathname === "/" + artist.url) 
                    {
                        this.home.selectedArtistIndex = index
                        this.artist.artistIndex = index
                        this.home.artistTransition()

                        return
                    } 
                    else if(index === artistsContent.length - 1)
                    {
                        if(window.location.pathname === '/') {
                            this.artist.outro()
                        }
                    }
                })

                this.location = window.location.pathname
            }
        }

        if(this.artist)
        {
            this.artist.update()

            if(this.artist.isIntroStarted)
            {
                this.home.isArtistTransitionDone = false
            }

            if(this.artist.isOutroDone)
            {
                this.home.isArtistOpen = false
                this.artist.isOutroDone = false
                this.isArtist = false
            }
        }
    }
}