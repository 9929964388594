import * as THREE from 'three'
import Experience from './Experience.js'

export default class Image
{
    constructor(imageName, imageSize, vertexShader, fragmentShader)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.time = this.experience.time

        this.texturesWidth = 0
        this.texturesHeight = 0

        this.img = imageName
        this.vertexShader = vertexShader
        this.fragmentShader = fragmentShader
        this.setGeometry(imageSize)
        this.setTextures(imageName)
        this.setMaterial()
        this.setMesh()
    }

    setGeometry(imageSize)
    {
        this.geometry = new THREE.PlaneGeometry(imageSize.width, imageSize.height, 32, 32)
    }

    setTextures(imageName)
    {
        if(imageName === '')
        {
            return
        }
        this.textures = {}
        this.textures.needsUpdate = true

        this.textures = imageName
        this.texturesWidth = this.textures.image.width
        this.texturesHeight = this.textures.image.height
    }

    setMaterial()
    {
        this.material = new THREE.ShaderMaterial({
            transparent: true,
            vertexShader: this.vertexShader,
            fragmentShader: this.fragmentShader,
            uniforms: {
                uTime: { value: 0 },
                uArtistIndex: { value: 0 },
                uHover: { value: 0 },
                uMouse: { value: new THREE.Vector2(0, 0) },
                uTexture: { value: this.textures },
                uNormalMap: { value: {} },
                uOpacity: { value: 0 },
                uSelected: { value: 0 },
                uPos: { value: new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2) },
                uRes: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
                uGeometrySize: { value: new THREE.Vector2(0) },
                uImageSize: { value: new THREE.Vector2(this.texturesWidth, this.texturesHeight) },
                uDPR : { value: Math.min(window.devicePixelRatio.toFixed(1), 2) },
            },
            defines: 
            {
                PR: Math.min(window.devicePixelRatio.toFixed(1), 2)
            }
        })
    }

    setMesh()
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.scene.add(this.mesh)
    }

    update()
    {
    }
}