import * as THREE from 'three'
import EventEmitter from './EventEmitter'

export default class Raycaster extends EventEmitter
{
    constructor(objects, camera)
    {
        super()

        // Setup
        this.raycaster = new THREE.Raycaster()
        this.objects = objects
        this.camera = camera
        this.mouse = new THREE.Vector2(-1, -1)
        window.addEventListener('mousemove', (e) => { this.onMouseMove(e) })
        this.preventClicking = false
        this.currentIntersect = null
        this.objectClicked = null

        this.mouseDownHandler = () =>
        {
            this.prevMouseX = this.mouse.x
            window.addEventListener('mouseup', this.mouseUpHandler)
        }

        this.mouseUpHandler = () =>
        {
            if(this.currentIntersect && this.prevMouseX === this.mouse.x && !this.preventClicking)
            {
                this.objectClicked = this.currentIntersect
            }
            window.removeEventListener('mouseup', this.mouseUpHandler)
        }
        window.addEventListener('mousedown', this.mouseDownHandler)
    }

    onMouseMove(event) 
    {
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
    }

    update()
    {
        if(this.mouse)
        {
            this.raycaster.setFromCamera(this.mouse, this.camera)
        }

        // calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects(this.objects)
        
        if(intersects.length)
        {
            this.currentIntersect = intersects[0]
        }
        else
        {
            this.currentIntersect = null
        }
    }
}