import * as THREE from 'three'
import { gsap, CustomEase } from 'gsap/all'
import Experience from '../Experience.js'
import Image from '../Image.js'
import imageVertexShader from '../Shaders/error/vertex.glsl'
import imageFragmentShader from '../Shaders/error/fragment.glsl'

export default class Error
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.time = this.experience.time
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeOutCubic', '0.33, 1, 0.68, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        // Setup
        this.isIntroStart = false

        this.textY = 100

        this.nbOfImages = 80
        this.nbOfRealImages = 43

        this.imageIndexArray = [3, 5, 6, 9, 10, 12, 14, 15, 16, 18, 19, 21, 22, 23, 25, 26, 28, 30, 33, 36, 37, 39, 40, 42, 44, 46, 48, 51, 53, 55, 57, 58, 59, 61, 62, 64, 66, 68, 69, 71, 74, 75, 78]
        this.shuffledImageIndexArray = this.shuffledArray([3, 5, 6, 9, 10, 12, 14, 15, 16, 18, 19, 21, 22, 23, 25, 26, 28, 30, 33, 36, 37, 39, 40, 42, 44, 46, 48, 51, 53, 55, 57, 58, 59, 61, 62, 64, 66, 68, 69, 71, 74, 75, 78])

        this.imageSize = {
            width: (this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127),
            height: (this.camera.heightToFit / 100) * (3175 / 256) 
        }

        this.portraitImageSize = {
            width: (this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127) * 4 + ((this.camera.widthToFit - (this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127) * 12) / 13) * 3,
            height: ((this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127) * 4 + ((this.camera.widthToFit - (this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127) * 12) / 13) * 3) * (227 / 185)
        }

        this.images = []
        this.imagesInitialPos = []
        this.imagesMesh = []
        this.imagesGroup = new THREE.Group()

        this.realImageIndex = 0
        for(let i = 0; i < this.nbOfImages; i++)
        {
            if(i === this.imageIndexArray[this.realImageIndex] && this.realImageIndex !== this.nbOfRealImages)
            {
                this.images[i] = new Image(this.resources.items[`portrait-${(this.realImageIndex - 14 * Math.floor(this.realImageIndex / 14))}`], this.imageSize, imageVertexShader, imageFragmentShader)

                this.images[i].material.uniforms.uGeometrySize.value.x = this.imageSize.width
                this.images[i].material.uniforms.uGeometrySize.value.y = this.imageSize.height

                this.realImageIndex += 1
            }
            else
            {
                this.images[i] = new Image('', this.imageSize, imageVertexShader, imageFragmentShader)
            }

            this.images[i].mesh.position.x = (this.imageSize.width / 2) + ((12 * (this.camera.widthToFit / window.innerWidth)) + this.imageSize.width) * (i - 8 * Math.floor(i / 8))
            this.images[i].mesh.position.y = -((this.imageSize.height / 2) + ((12 * (this.camera.widthToFit / window.innerWidth)) + this.imageSize.height) * Math.floor(i / 8))

            this.imagesMesh[i] = this.images[i].mesh

            this.imagesGroup.add(this.images[i].mesh)
        }

        // Position Images Group
        this.imagesGroupSize = new THREE.Vector3()

        this.imagesGroupBox = new THREE.Box3().setFromObject(this.imagesGroup)
        this.imagesGroupBox.getSize(this.imagesGroupSize)

        this.imagesGroup.position.x = (this.camera.widthToFit - this.imagesGroupSize.x) / 2
        this.imagesGroup.position.y = -(this.camera.heightToFit - this.imagesGroupSize.y) / 2

        this.scene.add(this.imagesGroup)

        // Drag event
        this.dragX = 0
        this.dragY = 0
        this.dragXValue = 0
        this.dragYValue = 0
        this.widthMargin = ((12 * (this.camera.widthToFit / window.innerWidth)) + this.imageSize.width)
        this.heightMargin = ((12 * (this.camera.widthToFit / window.innerWidth)) + this.imageSize.height)

        this.startX = 0
        this.startY = 0
        this.currentX = 0
        this.currentY = 0
        this.deltaX = 0
        this.deltaY = 0

        this.touchStartHandler = (e) =>
        {
            this.startX = e.touches[0].clientX
            this.startY = e.touches[0].clientY

            window.addEventListener('touchmove', this.touchMoveHandler)
            window.addEventListener('touchend', this.touchUpHandler)
        }

        this.touchMoveHandler = (e) =>
        {
            this.currentX = e.touches[0].clientX
            this.currentY = e.touches[0].clientY

            this.deltaX = this.currentX - this.startX
            this.deltaY = this.currentY - this.startY

            this.dragXValue += this.deltaX * .003
            this.dragYValue -= this.deltaY * .003

            this.startX = this.currentX
            this.startY = this.currentY
        }

        this.touchUpHandler = (e) =>
        {
            window.removeEventListener('touchmove', this.touchMoveHandler)
            window.removeEventListener('touchend', this.touchUpHandler)
        }

        window.addEventListener('touchstart', this.touchStartHandler)
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    shuffledArray(array)
    {
        for(let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array
    }

    intro()
    {
        this.isIntroStart = true

        gsap.set('.p404', {
            display: 'flex'
        })

        for(let i = 0; i < this.nbOfRealImages; i++)
        {
            gsap.to(this.images[this.shuffledImageIndexArray[i]].material.uniforms.uOpacity, {
                value: .5,
                duration: .5,
                delay: i * .025
            })
        }
    }

    update()
    {
        if(this.isIntroStart)
        {
            this.textY = this.lerp(this.textY, 0, .1)

            gsap.set('.p404 .first .text-mask', {
                y: `${this.textY}%`
            })

            gsap.set('.p404 .last .text-mask', {
                y: `${this.textY}%`,
                delay: .25
            })

            this.dragX = this.lerp(this.dragX, this.dragXValue, .1)
            this.dragY = this.lerp(this.dragY, this.dragYValue, .1)

            this.images.forEach((image, index) =>
            {
                image.mesh.position.x = ((index - 8 * Math.floor(index / 8)) * this.widthMargin + this.dragX + 10000 * (this.widthMargin * 8)) % (this.widthMargin * 8) + (this.imageSize.width / 2)
                image.mesh.position.y = (Math.floor(index / 8) * this.heightMargin + this.dragY + 10000 * (this.heightMargin * 10)) % (this.heightMargin * 10) - (this.heightMargin * 10) + (this.imageSize.height / 2) + (12 * (this.camera.widthToFit / window.innerWidth))
            })
        }
    }
}