import * as THREE from 'three'
import { gsap, CustomEase } from 'gsap/all'
import Experience from '../Experience.js'
import Image from '../Image.js'
import imageVertexShader from '../Shaders/error/vertex.glsl'
import imageFragmentShader from '../Shaders/error/fragment.glsl'
import Raycaster from '../Utils/Raycaster.js'
import artistsContent from '../artistsContent.js'

export default class Error
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.time = this.experience.time
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeOutCubic', '0.33, 1, 0.68, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        // Setup
        this.isIntroStart = false

        this.textY = 100

        this.nbOfImages = 126
        this.nbOfRealImages = 49

        this.imageIndexArray = [2, 4, 7, 10, 12, 16, 19, 21, 25, 27, 29, 31, 32, 34, 37, 38, 40, 41, 42, 44, 50, 52, 56, 60, 61, 63, 67, 68, 71, 75, 79, 83, 84, 87, 90, 91, 95, 97, 99, 100, 103, 107, 108, 110, 112, 115, 119, 120, 123]
        this.shuffledImageIndexArray = this.shuffledArray([2, 4, 7, 10, 12, 16, 19, 21, 25, 27, 29, 31, 32, 34, 37, 38, 40, 41, 42, 44, 50, 52, 56, 60, 61, 63, 67, 68, 71, 75, 79, 83, 84, 87, 90, 91, 95, 97, 99, 100, 103, 107, 108, 110, 112, 115, 119, 120, 123])

        this.imageSize = {
            width: (this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127),
            height: (this.camera.heightToFit / 100) * (3175 / 256) 
        }

        this.portraitImageSize = {
            width: (this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127) * 4 + ((this.camera.widthToFit - (this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127) * 12) / 13) * 3,
            height: ((this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127) * 4 + ((this.camera.widthToFit - (this.camera.heightToFit / 100) * (3175 / 256) * (95 / 127) * 12) / 13) * 3) * (227 / 185)
        }

        this.images = []
        this.imagesInitialPos = []
        this.imagesMesh = []
        this.imagesGroup = new THREE.Group()

        this.realImageIndex = 0
        for(let i = 0; i < this.nbOfImages; i++)
        {
            if(i === this.imageIndexArray[this.realImageIndex] && this.realImageIndex !== this.nbOfRealImages)
            {
                this.images[i] = new Image(this.resources.items[`portrait-${(this.realImageIndex - 14 * Math.floor(this.realImageIndex / 14))}`], this.imageSize, imageVertexShader, imageFragmentShader)

                this.images[i].material.uniforms.uGeometrySize.value.x = this.imageSize.width
                this.images[i].material.uniforms.uGeometrySize.value.y = this.imageSize.height

                this.realImageIndex += 1
            }
            else
            {
                this.images[i] = new Image('', this.imageSize, imageVertexShader, imageFragmentShader)
            }

            this.images[i].mesh.position.x = (this.imageSize.width / 2) + ((this.camera.widthToFit - this.imageSize.width * 12) / 13 + this.imageSize.width) * (i - 14 * Math.floor(i / 14))
            this.images[i].mesh.position.y = -((this.imageSize.height / 2) + ((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * Math.floor(i / 14))

            this.imagesMesh[i] = this.images[i].mesh

            this.imagesGroup.add(this.images[i].mesh)
        }

        // Position Images Group
        this.imagesGroupSize = new THREE.Vector3()

        this.imagesGroupBox = new THREE.Box3().setFromObject(this.imagesGroup)
        this.imagesGroupBox.getSize(this.imagesGroupSize)

        this.imagesGroup.position.x = (this.camera.widthToFit - this.imagesGroupSize.x) / 2
        this.imagesGroup.position.y = -(this.camera.heightToFit - this.imagesGroupSize.y) / 2

        this.scene.add(this.imagesGroup)

        // Scroll
        this.scrollX = 0
        this.scrollY = 0
        this.scrollXValue = 0
        this.scrollYValue = 0
        this.widthMargin = ((this.camera.widthToFit - this.imageSize.width * 12) / 13 + this.imageSize.width)
        this.heightMargin = ((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height)

        window.addEventListener('wheel', e =>
        {
            this.scrollXValue -= e.deltaX * .001
            this.scrollYValue += e.deltaY * .001
        })

        // Drag event
        this.mouse = {
            x: 0,
            y: 0
        }

        this.mouseMoveHandler = (e) =>
        {
            this.mouse.x = (-(e.clientX / window.innerWidth) * 2 + 1) * .05
            this.mouse.y = ((e.clientY / window.innerHeight) * 2 - 1) * .05
        }

        window.addEventListener('mousemove', this.mouseMoveHandler)
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    shuffledArray(array)
    {
        for(let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array
    }

    intro()
    {
        this.isIntroStart = true

        gsap.set('.p404', {
            display: 'flex'
        })

        for(let i = 0; i < this.nbOfRealImages; i++)
        {
            gsap.to(this.images[this.shuffledImageIndexArray[i]].material.uniforms.uOpacity, {
                value: .5,
                duration: .5,
                delay: i * .025
            })
        }
    }

    update()
    {
        if(this.isIntroStart)
        {
            this.textY = this.lerp(this.textY, 0, .1)

            gsap.set('.p404 .first .text-mask', {
                y: `${this.textY}%`
            })

            gsap.set('.p404 .last .text-mask', {
                y: `${this.textY}%`,
                delay: .25
            }) 
            
            this.scrollXValue += this.mouse.x
            this.scrollYValue += this.mouse.y

            this.scrollX = this.lerp(this.scrollX, this.scrollXValue, .1)
            this.scrollY = this.lerp(this.scrollY, this.scrollYValue, .1)

            this.images.forEach((image, index) =>
            {
                image.mesh.position.x = ((index - 14 * Math.floor(index / 14)) * this.widthMargin + this.scrollX + 10000 * (this.widthMargin * 14)) % (this.widthMargin * 14) + (this.imageSize.width / 2)
                image.mesh.position.y = (Math.floor(index / 14) * this.heightMargin + this.scrollY + 10000 * (this.heightMargin * 9)) % (this.heightMargin * 9) - (this.heightMargin * 9) + (this.camera.heightToFit / 100) * (125 / 64) / 2
            })
        }
    }
}