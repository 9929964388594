import * as THREE from 'three'
import { gsap, CustomEase } from 'gsap/all'
import Split from '../Utils/Split.js'
import Experience from '../Experience.js'
import Image from '../Image.js'
import imageVertexShader from '../Shaders/artist/vertex.glsl'
import imageFragmentShader from '../Shaders/artist/fragment.glsl'
import portraitVertexShader from '../Shaders/artist/portrait/vertex.glsl'
import portraitFragmentShader from '../Shaders/artist/portrait/fragment.glsl'
import coverVertexShader from '../Shaders/artist/cover/vertex.glsl'
import coverFragmentShader from '../Shaders/artist/cover/fragment.glsl'
import Raycaster from '../Utils/Raycaster.js'
import artistsContent from '../artistsContent.js'

export default class Artist
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.time = this.experience.time
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeOutCubic', '0.33, 1, 0.68, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        // Setup
        this.isResizing = false
        this.isMenuOpen = false
        this.isIntroStarted = false
        this.isIntroDone = false
        this.isOutroStarted = false
        this.isOutroDone = false
        this.isTransitionStarted = false
        this.isTransitionImagePlaced = false
        this.isArtistSet = false
        this.linesSplitted = false

        this.presentationTextY = 100
        this.presentationTextYValue = 100
        this.secondDescriptionY = 100
        this.project0Y = 100
        this.project1Y = 100
        this.project2Y = 100
        this.project3Y = 100
        this.nextArtistTextY = 100
        this.outroY = 100

        this.screenRatio = window.innerWidth / this.camera.widthToFit

        if(window.innerWidth / window.innerHeight < 1.5)
        {
            this.columnWidth = (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127)
        }
        else
        {
            this.columnWidth = (this.camera.heightToFit / 100) * (127 / 9.8) * (95 / 127)
        }

        this.spaceBetweenColumns = (this.camera.widthToFit - this.columnWidth * 12) / 13

        this.secondDescriptionHeight = 0

        this.artistIndex = 0
        this.nbOfProjects = [
            3,
            4,
            3,
            4,
            3,
            3,
            3,
            3,
            4,
            3,
            4,
            3,
            3,
            3
        ]

        this.gridImageSize = {
            width: this.columnWidth,
            height: this.columnWidth / (95 / 127)
        }

        this.portraitImageSize = {
            width: this.columnWidth * 4 + this.spaceBetweenColumns * 3,
            height: (this.columnWidth * 4 + this.spaceBetweenColumns * 3) * (227 / 185)
        }

        this.portraitImageXPos = (this.portraitImageSize.width / 2) + ((this.camera.widthToFit - this.gridImageSize.width * 12) / 13) 

        this.coverImageSize = {
            width: this.columnWidth * 8 + this.spaceBetweenColumns * 7,
            height: (this.columnWidth * 8 + this.spaceBetweenColumns * 7) * (89 / 150)
        }

        this.projectImageSize = {
            width: this.columnWidth * 2 + this.spaceBetweenColumns,
            height: (this.columnWidth * 2 + this.spaceBetweenColumns) * (281 / 210)
        }

        this.nextArtistImageSize = {
            width: this.columnWidth * 3 + this.spaceBetweenColumns * 2,
            height: (this.columnWidth * 3 + this.spaceBetweenColumns * 2) * (227 / 185)
        }

        this.portraitImage = null
        this.coverImage = null
        this.projectImages = []
        this.projectImagesMesh = []
        this.nextArtistImage = null
        this.nextArtistImageYPos = null
        this.imagesMesh = []

        // Raycaster
        this.raycaster = new Raycaster(this.imagesMesh, this.camera.instance)

        this.raycasterProjects = new Raycaster(this.projectImagesMesh, this.camera.instance)
        this.prevClickedImage = null
        this.prevClickedImageIndex = 0
        this.clickedImage = null
        this.visibleTexts = []
        this.navTextY = 0
        this.artistTextY = 0
        this.closeTextY = 100

        document.querySelector('nav .close-zoomed-img').addEventListener('click', () => 
        {
            this.raycasterProjects.objectClicked = null
            this.prevClickedImage = this.clickedImage
            this.clickedImage = null
        })

        window.addEventListener('click', () => 
        {
            if(this.clickedImage)
            {
                this.raycasterProjects.objectClicked = null
                this.prevClickedImage = this.clickedImage
                this.clickedImage = null
            }
        })

        // Scroll
        this.cameraScroll = -(this.camera.heightToFit / 2)
        this.htmlScroll = 0
        this.htmlScrollValue = 0

        window.addEventListener('wheel', e =>
        {
            if(this.isIntroDone && !this.isTransitionStarted && !this.isMenuOpen && !this.clickedImage)
            {
                this.cameraScroll = Math.max(Math.min(this.cameraScroll - e.deltaY * .001, -(this.camera.heightToFit / 2)), this.scrollMaxValue)
                this.htmlScroll = Math.min(Math.max(this.htmlScroll - e.deltaY * (.001 * this.screenRatio), this.htmlScrollMaxValue), 0)
            }
        })

        // Drag event
        this.mouseDownHandler = () =>
        {
            if(this.isIntroDone && !this.isTransitionStarted && !this.isMenuOpen && !this.clickedImage)
            {
                window.addEventListener('mousemove', this.mouseMoveHandler)
                window.addEventListener('mouseup', this.mouseUpHandler)
            }
        }

        this.mouseMoveHandler = (e) =>
        {
            this.cameraScroll = Math.max(Math.min(this.cameraScroll + e.movementY * .002, -(this.camera.heightToFit / 2)), this.scrollMaxValue)
            this.htmlScroll = Math.min(Math.max(this.htmlScroll + e.movementY * (.002 * this.screenRatio), this.htmlScrollMaxValue), 0)
        }

        this.mouseUpHandler = () =>
        {
            window.removeEventListener('mousemove', this.mouseMoveHandler)
            window.removeEventListener('mouseup', this.mouseUpHandler)
        }

        window.addEventListener('mousedown', this.mouseDownHandler)

        // Next artist
        document.querySelector('.artist .next').addEventListener('click', () => 
        {
            if(!this.isTransitionStarted)
            {
                this.transitionBetweenArtists()

                this.artistIndex === 13 ? this.artistIndex = 0 : this.artistIndex++

                history.pushState({}, '', `/${artistsContent[this.artistIndex].url}`)
                document.title = `${artistsContent[this.artistIndex].name}`
            }
        })

        // Mouse
        this.mouse = new THREE.Vector2(0, 0)
        window.addEventListener('mousemove', e => { this.onMouseMove(e) })
    }

    onMouseMove(event) {
        gsap.to(this.mouse, {
            x: -(event.clientX / window.innerWidth) * 2 + 1,
            y: (event.clientY / window.innerHeight) * 2 - 1,
            delay: .05
        })
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    setHtml()
    {
        this.linesSplitted = false

        document.querySelector('.artist .presentation .name').innerHTML = artistsContent[this.artistIndex].name
        document.querySelector('.artist .presentation .job').innerHTML = artistsContent[this.artistIndex].job
        document.querySelector('.artist .description').innerHTML = artistsContent[this.artistIndex].description
        document.querySelector('.artist .second-description').innerHTML = artistsContent[this.artistIndex].secondDescription
        document.querySelector('.artist .projects .p-0 .p-name').innerHTML = artistsContent[this.artistIndex].projectName0
        document.querySelector('.artist .projects .p-0 .p-date').innerHTML = artistsContent[this.artistIndex].projectDate0
        document.querySelector('.artist .projects .p-1 .p-name').innerHTML = artistsContent[this.artistIndex].projectName1
        document.querySelector('.artist .projects .p-1 .p-date').innerHTML = artistsContent[this.artistIndex].projectDate1
        document.querySelector('.artist .projects .p-2 .p-name').innerHTML = artistsContent[this.artistIndex].projectName2
        document.querySelector('.artist .projects .p-2 .p-date').innerHTML = artistsContent[this.artistIndex].projectDate2

        if(this.nbOfProjects[this.artistIndex] === 4)
        {
            document.querySelector('.artist .projects .p-3 .p-name').innerHTML = artistsContent[this.artistIndex].projectName3
            document.querySelector('.artist .projects .p-3 .p-date').innerHTML = artistsContent[this.artistIndex].projectDate3
        }
        else
        {
            document.querySelector('.artist .projects .p-3 .p-name').innerHTML = ''
            document.querySelector('.artist .projects .p-3 .p-date').innerHTML = ''
        }

        if(this.artistIndex === 13)
        {
            document.querySelector('.artist .next .next-name').innerHTML = artistsContent[0].name
        }
        else
        {
            document.querySelector('.artist .next .next-name').innerHTML = artistsContent[this.artistIndex + 1].name
        }

        gsap.set('.artist .description div', {
            opacity: 0
        })
        
        Split('lines', '.artist .presentation div')
    }

    setArtist()
    {
        this.imagesMesh = []

        // Portrait Image
        this.portraitImage = new Image(this.resources.items[`portrait-${this.artistIndex}`], this.portraitImageSize, portraitVertexShader, portraitFragmentShader)

        this.portraitImage.material.uniforms.uNormalMap.value = this.resources.items[`portrait-normal-${this.artistIndex}`]

        this.portraitImage.mesh.position.x = (this.portraitImageSize.width / 2) + ((this.camera.widthToFit - this.gridImageSize.width * 12) / 13)
        this.portraitImage.mesh.position.y = -(this.camera.heightToFit / 2)

        this.portraitImage.material.uniforms.uGeometrySize.value.x = this.portraitImageSize.width
        this.portraitImage.material.uniforms.uGeometrySize.value.y = this.portraitImageSize.height

        this.portraitImage.material.uniforms.uOpacity.value = 1

        this.imagesMesh.push(this.portraitImage.mesh)

        // Cover Image
        this.coverImage = new Image(this.resources.items[`cover-${this.artistIndex}`], this.coverImageSize, coverVertexShader, coverFragmentShader)

        this.coverImage.mesh.position.x = (this.camera.widthToFit / 2)
        this.coverImage.mesh.position.y = -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height / 2)

        this.coverImage.material.uniforms.uGeometrySize.value.x = this.coverImageSize.width
        this.coverImage.material.uniforms.uGeometrySize.value.y = this.coverImageSize.height

        this.coverImage.material.uniforms.uOpacity.value = 1

        this.imagesMesh.push(this.coverImage.mesh)

        // Projects Images
        this.projectImagePos = [
            {
                x: (this.projectImageSize.width / 2) + this.columnWidth + this.spaceBetweenColumns * 2,
                y: -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.projectImageSize.height / 2 + this.secondDescriptionHeight + this.columnWidth * 2)
            },
            {
                x: (this.projectImageSize.width / 2) + this.columnWidth * 7 + this.spaceBetweenColumns * 8,
                y: -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.projectImageSize.height / 2 + this.secondDescriptionHeight + this.columnWidth * 2 + this.projectImageSize.height + this.spaceBetweenColumns)
            },
            {
                x: (this.projectImageSize.width / 2) + this.columnWidth * 3 + this.spaceBetweenColumns * 4,
                y: -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.projectImageSize.height / 2 + this.secondDescriptionHeight + this.columnWidth * 2 + this.projectImageSize.height * 2 + this.spaceBetweenColumns * 2)
            },
            {
                x: (this.projectImageSize.width / 2) + this.columnWidth * 9 + this.spaceBetweenColumns * 10,
                y: -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.projectImageSize.height / 2 + this.secondDescriptionHeight + this.columnWidth * 2 + this.projectImageSize.height * 3 + this.spaceBetweenColumns * 3)
            }
        ]

        this.projectImages = []
        this.projectImagesMesh = []

        for(let i = 0; i < this.nbOfProjects[this.artistIndex]; i++)
        {
            this.projectImages[i] = new Image(this.resources.items[`project-${this.artistIndex}-${i}`], this.projectImageSize, imageVertexShader, imageFragmentShader)

            this.projectImages[i].mesh.frustumCulled = false

            this.projectImages[i].mesh.position.x = this.projectImagePos[i].x
            this.projectImages[i].mesh.position.y = this.projectImagePos[i].y

            this.projectImages[i].material.uniforms.uGeometrySize.value.x = this.projectImageSize.width
            this.projectImages[i].material.uniforms.uGeometrySize.value.y = this.projectImageSize.height

            this.projectImages[i].material.uniforms.uOpacity.value = 1

            this.projectImagesMesh.push(this.projectImages[i].mesh)
        }

        this.raycasterProjects.objects = this.projectImagesMesh

        // Next Artist Image
        if(this.artistIndex === 13)
        {
            this.nextArtistImage = new Image(this.resources.items['portrait-0'], this.nextArtistImageSize, portraitVertexShader, portraitFragmentShader)

            this.nextArtistImage.material.uniforms.uNormalMap.value = this.resources.items['portrait-normal-0']
        }
        else
        {
            this.nextArtistImage = new Image(this.resources.items[`portrait-${this.artistIndex + 1}`], this.nextArtistImageSize, portraitVertexShader, portraitFragmentShader)

            this.nextArtistImage.material.uniforms.uNormalMap.value = this.resources.items[`portrait-normal-${this.artistIndex + 1}`]
        }

        this.nextArtistImage.mesh.position.x = (this.nextArtistImageSize.width / 2) + ((this.camera.widthToFit - this.gridImageSize.width * 12) / 13)

        if(this.nbOfProjects[this.artistIndex] === 3)
        {
            this.nextArtistImage.mesh.position.y = -(this.camera.heightToFit + this.nextArtistImageSize.height / 2 + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 3 + this.spaceBetweenColumns * 2)
            this.nextArtistImageYPos = this.nextArtistImage.mesh.position.y
            document.querySelector('.artist .next').style.top = 'calc(100vh + var(--space-between-columns) * 2 + var(--cover-image-height) + 1.25rem + var(--second-description-height) + (var(--column-width) * 4) + (var(--project-image-height) * 3) + (var(--space-between-columns) * 2))'

            this.scrollMaxValue = -(this.camera.heightToFit / 2 + this.nextArtistImageSize.height + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 3 + this.spaceBetweenColumns * 3)
            this.htmlScrollMaxValue = -(this.nextArtistImageSize.height + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 3 + this.spaceBetweenColumns * 3) * this.screenRatio
        }
        else
        {
            this.nextArtistImage.mesh.position.y = -(this.camera.heightToFit + this.nextArtistImageSize.height / 2 + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 4 + this.spaceBetweenColumns * 3)
            this.nextArtistImageYPos = this.nextArtistImage.mesh.position.y
            document.querySelector('.artist .next').style.top = 'calc(100vh + var(--space-between-columns) * 2 + var(--cover-image-height) + 1.25rem + var(--second-description-height) + (var(--column-width) * 4) + (var(--project-image-height) * 4) + (var(--space-between-columns) * 3))'

            this.scrollMaxValue = -(this.camera.heightToFit / 2 + this.nextArtistImageSize.height + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 4 + this.spaceBetweenColumns * 4)
            this.htmlScrollMaxValue = -(this.nextArtistImageSize.height + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 4 + this.spaceBetweenColumns * 4) * this.screenRatio
        }

        this.nextArtistImage.material.uniforms.uGeometrySize.value.x = this.nextArtistImageSize.width
        this.nextArtistImage.material.uniforms.uGeometrySize.value.y = this.nextArtistImageSize.height

        this.nextArtistImage.material.uniforms.uOpacity.value = 1

        this.imagesMesh.push(this.nextArtistImage.mesh)

        this.raycaster.objects = this.imagesMesh

        this.isArtistSet = true

        this.intro()
    }

    intro()
    {
        this.isTransitionStarted = false
        this.isTransitionImagePlaced = false
        this.isIntroStarted = true
        this.isOutroStarted = false

        gsap.set('.artist', {
            display: 'block'
        })

        this.presentationTextY = 0
        this.presentationTextYValue = 100

        document.querySelector('nav a').addEventListener('click', e =>
        {
            if(this.isIntroStarted && !this.clickedImage && !this.isMenuOpen)
            {
                e.preventDefault()
    
                history.pushState({}, '', '/')
                document.title = 'Gallery of fame'
            }
        })
    }

    outro()
    {
        this.isOutroStarted = true

        this.outroY = 0

        this.presentationTextY = 100
        this.presentationTextYValue = 100
        this.secondDescriptionY = 100
        this.project0Y = 100
        this.project1Y = 100
        this.project2Y = 100
        this.project3Y = 100
        this.nextArtistTextY = 100
    }

    transitionBetweenArtists() 
    {
        this.isTransitionStarted = true

        if(this.htmlScrollValue !== this.htmlScrollMaxValue)
        {
            this.htmlScroll = this.htmlScrollMaxValue
            this.cameraScroll = this.scrollMaxValue
        }

        this.transitionImageScale = {
            x: this.portraitImageSize.width / this.nextArtistImageSize.width,
            y: this.portraitImageSize.height / this.nextArtistImageSize.height
        }

        this.transitionImagePosition = {
            x: (this.portraitImageSize.width / 2) + ((this.camera.widthToFit - this.gridImageSize.width * 12) / 13),
            y: this.scrollMaxValue
        }

        this.presentationTextY = 100
        this.presentationTextYValue = 100
        this.jobTextY = 100
        this.secondDescriptionY = 100
        this.project0Y = 100
        this.project1Y = 100
        this.project2Y = 100
        this.project3Y = 100
        this.nextArtistTextY = 100
        this.outroY = 0
    }

    dispose()
    {
        this.portraitImage.material.uniforms.uOpacity.value = 0
        this.portraitImage.geometry.dispose()
        this.portraitImage.material.dispose()
        this.portraitImage.textures.dispose()

        this.coverImage.material.uniforms.uOpacity.value = 0
        this.coverImage.geometry.dispose()
        this.coverImage.material.dispose()
        this.coverImage.textures.dispose()

        this.projectImages.forEach(image => 
        {
            image.material.uniforms.uOpacity.value = 0
            image.geometry.dispose()
            image.material.dispose()
            image.textures.dispose()
        })

        this.nextArtistImage.material.uniforms.uOpacity.value = 0
        this.nextArtistImage.geometry.dispose()
        this.nextArtistImage.material.dispose()
        this.nextArtistImage.textures.dispose()
    }

    resize()
    {
        if(this.isArtistSet)
        {
            this.dispose()
        }

        if(this.scrollMaxValue < this.cameraScroll)
        {
            this.htmlScroll = this.htmlScrollMaxValue
            this.cameraScroll = this.scrollMaxValue
        }
        
        this.screenRatio = window.innerWidth / this.camera.widthToFit

        if(window.innerWidth / window.innerHeight < 1.5)
        {
            this.columnWidth = (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127)
        }
        else
        {
            this.columnWidth = (this.camera.heightToFit / 100) * (127 / 9.8) * (95 / 127)
        }

        this.spaceBetweenColumns = (this.camera.widthToFit - this.columnWidth * 12) / 13

        this.gridImageSize = {
            width: this.columnWidth,
            height: this.columnWidth / (95 / 127)
        }

        this.portraitImageSize = {
            width: this.columnWidth * 4 + this.spaceBetweenColumns * 3,
            height: (this.columnWidth * 4 + this.spaceBetweenColumns * 3) * (227 / 185)
        }

        this.portraitImageXPos = (this.portraitImageSize.width / 2) + ((this.camera.widthToFit - this.gridImageSize.width * 12) / 13) 

        this.coverImageSize = {
            width: this.columnWidth * 8 + this.spaceBetweenColumns * 7,
            height: (this.columnWidth * 8 + this.spaceBetweenColumns * 7) * (89 / 150)
        }

        this.projectImageSize = {
            width: this.columnWidth * 2 + this.spaceBetweenColumns,
            height: (this.columnWidth * 2 + this.spaceBetweenColumns) * (281 / 210)
        }

        this.nextArtistImageSize = {
            width: this.columnWidth * 3 + this.spaceBetweenColumns * 2,
            height: (this.columnWidth * 3 + this.spaceBetweenColumns * 2) * (227 / 185)
        }

        if(this.isArtistSet)
        {
            this.setHtml()
            this.setArtist()
        }
    }

    update()
    {
        if(!this.isResizing)
        {
            if(this.raycaster && this.isIntroDone)
            {
                this.raycaster.update()

                if(this.raycaster.currentIntersect && !this.isTransitionStarted)
                {
                    this.raycaster.currentIntersect.object.material.uniforms.uHover.value = this.lerp(this.raycaster.currentIntersect.object.material.uniforms.uHover.value, 1, .05)
                }
                else
                {
                    this.portraitImage.material.uniforms.uHover.value = this.lerp(this.portraitImage.material.uniforms.uHover.value, 0, .1)
                    this.coverImage.material.uniforms.uHover.value = this.lerp(this.coverImage.material.uniforms.uHover.value, 0, .1)
                    this.nextArtistImage.material.uniforms.uHover.value = this.lerp(this.nextArtistImage.material.uniforms.uHover.value, 0, .1)
                }
            }

            if(this.raycasterProjects && this.isIntroDone && !this.isTransitionStarted && !this.isOutroStarted)
            {
                this.raycasterProjects.update()

                if(this.raycasterProjects.currentIntersect && !this.clickedImage)
                {
                    document.body.style.cursor = 'pointer'
                }
                else if(!this.clickedImage)
                {
                    document.body.style.cursor = 'default'
                }

                if(this.raycasterProjects.objectClicked || this.clickedImage)
                {
                    if(!this.clickedImage)
                    {
                        this.clickedImage = this.raycasterProjects.objectClicked.object
                        document.body.style.cursor = 'default'
                    }
                    else
                    {
                        this.navTextY = this.lerp(this.navTextY, -100, .1)
                        gsap.set('nav a span:nth-of-type(2), nav .menu-btn span:first-of-type', {
                            y: `${this.navTextY}%`,
                            force3D: true
                        })

                        this.artistTextY = this.lerp(this.artistTextY, -100, .1)
                        gsap.set('.artist .word.visible', {
                            y: `${this.artistTextY}%`,
                            force3D: true
                        })

                        this.closeTextY = this.lerp(this.closeTextY, 0, .1)
                        gsap.set('nav .close-zoomed-img', {
                            zIndex: 100
                        })
                        gsap.set('nav .close-zoomed-img span', {
                            y: `${this.closeTextY}%`,
                            force3D: true
                        })

                        if(this.navTextY <= -90)
                        {
                            this.portraitImage.mesh.position.y = this.lerp(this.portraitImage.mesh.position.y, this.camera.instance.position.y + (this.camera.heightToFit / 2) + this.portraitImageSize.height, .1)
                            this.coverImage.mesh.position.y = this.lerp(this.coverImage.mesh.position.y, this.camera.instance.position.y + (this.camera.heightToFit / 2) + this.coverImageSize.height, .1)
                            this.nextArtistImage.mesh.position.y = this.lerp(this.nextArtistImage.mesh.position.y, this.camera.instance.position.y - (this.camera.heightToFit / 2) - this.nextArtistImageSize.height, .1)

                            this.projectImages.forEach((project, index) => 
                            {
                                if(project.mesh !== this.clickedImage)
                                {
                                    if(index % 2 === 0)
                                    {
                                        project.mesh.position.x = this.lerp(project.mesh.position.x, -this.projectImageSize.width, .1)
                                    }
                                    else
                                    {
                                        project.mesh.position.x = this.lerp(project.mesh.position.x, this.camera.widthToFit + this.projectImageSize.width, .1)
                                    }
                                }
                            })

                            this.clickedImage.scale.x = this.lerp(this.clickedImage.scale.x, (this.camera.heightToFit / this.projectImageSize.width) * (this.clickedImage.material.uniforms.uImageSize.value.x / this.clickedImage.material.uniforms.uImageSize.value.y), .1)
                            this.clickedImage.scale.y = this.lerp(this.clickedImage.scale.y, this.camera.heightToFit / this.projectImageSize.height, .1)

                            this.clickedImage.material.uniforms.uGeometrySize.value.x = this.lerp(this.clickedImage.material.uniforms.uGeometrySize.value.x, this.projectImageSize.width * (this.camera.heightToFit / this.projectImageSize.width) * (this.clickedImage.material.uniforms.uImageSize.value.x / this.clickedImage.material.uniforms.uImageSize.value.y), .1)
                            this.clickedImage.material.uniforms.uGeometrySize.value.y = this.lerp(this.clickedImage.material.uniforms.uGeometrySize.value.y, this.projectImageSize.height * this.camera.heightToFit / this.projectImageSize.height, .1)

                            this.clickedImage.position.x = this.lerp(this.clickedImage.position.x, this.camera.widthToFit / 2, .1)
                            this.clickedImage.position.y = this.lerp(this.clickedImage.position.y, this.camera.instance.position.y, .1)
                        }
                    }
                }
                else if(this.prevClickedImage)
                {
                    this.projectImages.forEach((project, index) => 
                    {
                        if(project.mesh === this.prevClickedImage)
                        {
                            this.prevClickedImageIndex = index

                            project.mesh.scale.x = this.lerp(project.mesh.scale.x, 1, .1)
                            project.mesh.scale.y = this.lerp(project.mesh.scale.y, 1, .1)

                            project.material.uniforms.uGeometrySize.value.x = this.lerp(project.material.uniforms.uGeometrySize.value.x, this.projectImageSize.width, .1)
                            project.material.uniforms.uGeometrySize.value.y = this.lerp(project.material.uniforms.uGeometrySize.value.y, this.projectImageSize.height, .1)
                        }

                        project.mesh.position.x = this.lerp(project.mesh.position.x, this.projectImagePos[index].x, .1)
                        project.mesh.position.y = this.lerp(project.mesh.position.y, this.projectImagePos[index].y, .1)
                    })

                    this.portraitImage.mesh.position.y = this.lerp(this.portraitImage.mesh.position.y, -(this.camera.heightToFit / 2), .1)
                    this.coverImage.mesh.position.y = this.lerp(this.coverImage.mesh.position.y, -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height / 2), .1)
                    this.nextArtistImage.mesh.position.y = this.lerp(this.nextArtistImage.mesh.position.y, this.nextArtistImageYPos, .1)

                    if(this.navTextY <= -2.5)
                    {
                        this.navTextY = this.lerp(this.navTextY, 0, .1)
                        gsap.set('nav a span:nth-of-type(2), nav .menu-btn span:first-of-type', {
                            y: `${this.navTextY}%`,
                            force3D: true
                        })
        
                        this.closeTextY = this.lerp(this.closeTextY, 100, .1)
                        gsap.set('nav .close-zoomed-img', {
                            zIndex: -100
                        })
                        gsap.set('nav .close-zoomed-img span', {
                            y: `${this.closeTextY}%`,
                            force3D: true
                        })
                    }

                    if(Math.abs(this.prevClickedImage.position.x - this.projectImagePos[this.prevClickedImageIndex].x) <= 0.005)
                    {
                        this.artistTextY = this.lerp(this.artistTextY, 0, .1)
                        gsap.set('.artist .word.visible', {
                            y: `${this.artistTextY}%`,
                            force3D: true
                        })
                    }

                    if(this.artistTextY >= -0.1)
                    {
                        this.prevClickedImage = null
                    }
                }
            }

            if(this.nextArtistImage) 
            {
                this.portraitImage.material.uniforms.uMouse.value = this.mouse
                this.nextArtistImage.material.uniforms.uMouse.value = this.mouse
            }

            if(document.querySelector('.artist .description').offsetTop !== 0 && !this.linesSplitted)
            {
                this.linesSplitted = true

                Split('lines', '.artist .description div')
                Split('lines', '.artist .second-description div')
                Split('lines', '.artist .projects .p div')
                Split('lines', '.artist .next div')

                this.secondDescriptionHeight = (document.querySelector('.artist .second-description').offsetHeight + 20) / this.screenRatio
                document.documentElement.style.setProperty('--second-description-height', `${document.querySelector('.artist .second-description').offsetHeight}px`);

                this.scrollMaxValue -= this.secondDescriptionHeight
                this.htmlScrollMaxValue -= this.secondDescriptionHeight * this.screenRatio

                this.projectImagePos = [
                    {
                        x: (this.projectImageSize.width / 2) + this.columnWidth + this.spaceBetweenColumns * 2,
                        y: -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.projectImageSize.height / 2 + this.secondDescriptionHeight + this.columnWidth * 2)
                    },
                    {
                        x: (this.projectImageSize.width / 2) + this.columnWidth * 7 + this.spaceBetweenColumns * 8,
                        y: -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.projectImageSize.height / 2 + this.secondDescriptionHeight + this.columnWidth * 2 + this.projectImageSize.height + this.spaceBetweenColumns)
                    },
                    {
                        x: (this.projectImageSize.width / 2) + this.columnWidth * 3 + this.spaceBetweenColumns * 4,
                        y: -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.projectImageSize.height / 2 + this.secondDescriptionHeight + this.columnWidth * 2 + this.projectImageSize.height * 2 + this.spaceBetweenColumns * 2)
                    },
                    {
                        x: (this.projectImageSize.width / 2) + this.columnWidth * 9 + this.spaceBetweenColumns * 10,
                        y: -(this.camera.heightToFit + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.projectImageSize.height / 2 + this.secondDescriptionHeight + this.columnWidth * 2 + this.projectImageSize.height * 3 + this.spaceBetweenColumns * 3)
                    }
                ]

                this.projectImages.forEach((project, index) => 
                {
                    project.mesh.position.y = this.projectImagePos[index].y
                })

                if(this.nbOfProjects[this.artistIndex] === 3)
                {
                    this.nextArtistImage.mesh.position.y = -(this.camera.heightToFit + this.nextArtistImageSize.height / 2 + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 3 + this.spaceBetweenColumns * 2)
                    this.nextArtistImageYPos = this.nextArtistImage.mesh.position.y
                    document.querySelector('.artist .next').style.top = 'calc(100vh + var(--space-between-columns) * 2 + var(--cover-image-height) + 1.25rem + var(--second-description-height) + (var(--column-width) * 4) + (var(--project-image-height) * 3) + (var(--space-between-columns) * 2))'
        
                    this.scrollMaxValue = -(this.camera.heightToFit / 2 + this.nextArtistImageSize.height + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 3 + this.spaceBetweenColumns * 3)
                    this.htmlScrollMaxValue = -(this.nextArtistImageSize.height + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 3 + this.spaceBetweenColumns * 3) * this.screenRatio
                }
                else
                {
                    this.nextArtistImage.mesh.position.y = -(this.camera.heightToFit + this.nextArtistImageSize.height / 2 + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 4 + this.spaceBetweenColumns * 3)
                    this.nextArtistImageYPos = this.nextArtistImage.mesh.position.y
                    document.querySelector('.artist .next').style.top = 'calc(100vh + var(--space-between-columns) * 2 + var(--cover-image-height) + 1.25rem + var(--second-description-height) + (var(--column-width) * 4) + (var(--project-image-height) * 4) + (var(--space-between-columns) * 3))'
        
                    this.scrollMaxValue = -(this.camera.heightToFit / 2 + this.nextArtistImageSize.height + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 4 + this.spaceBetweenColumns * 4)
                    this.htmlScrollMaxValue = -(this.nextArtistImageSize.height + this.spaceBetweenColumns * 2 + this.coverImageSize.height + this.secondDescriptionHeight + this.columnWidth * 4 + this.projectImageSize.height * 4 + this.spaceBetweenColumns * 4) * this.screenRatio
                }

                gsap.set('.artist .description div', {
                    opacity: 1
                })
            }

            if(this.isIntroStarted)
            {
                this.isIntroDone = true

                this.presentationTextYValue = this.lerp(this.presentationTextYValue, this.presentationTextY, .075)

                gsap.set('.artist .presentation .name .word', {
                    y: `${this.presentationTextYValue}%`,
                    force3D: true
                })

                gsap.set('.artist .presentation .job .word', {
                    y: `${this.presentationTextYValue}%`,
                    force3D: true,
                    delay: .25
                })

                if(document.querySelector('.artist .description .word'))
                {
                    document.querySelectorAll('.artist .description .line').forEach((line, index) => 
                    {
                        gsap.set(line.children, {
                            y: `${this.presentationTextYValue}%`,
                            force3D: true,
                            delay: .5 + index * .125
                        })
                    })
                }
            }

            if(this.isIntroDone && !this.isTransitionImagePlaced)
            {
                this.camera.instance.position.y = this.lerp(this.camera.instance.position.y, this.cameraScroll, .1)

                this.htmlScrollValue = this.lerp(this.htmlScrollValue, this.htmlScroll, .1)

                gsap.set('.artist', {
                    y: this.htmlScrollValue,
                    force3D: true
                })

                if(!this.isTransitionStarted && !this.clickedImage && !this.prevClickedImage)
                // if(!this.isTransitionStarted)
                {
                    if(this.cameraScroll <= this.coverImage.mesh.position.y || this.secondDescriptionY < 100 && this.secondDescriptionY >= 0.001)
                    {
                        this.secondDescriptionY = this.lerp(this.secondDescriptionY, 0, .075)

                        document.querySelectorAll('.artist .second-description .line').forEach((line, index) => 
                        {
                            gsap.set(line.children, {
                                y: this.secondDescriptionY,
                                force3D: true,
                                delay: index * .125
                            })
                        })

                        if(document.querySelector('.artist .second-description .word') && !document.querySelector('.artist .second-description .word').classList.contains('visible'))
                        {
                            document.querySelectorAll('.artist .second-description .word').forEach(word =>
                            {
                                word.classList.add('visible')
                            })
                        }
                    }
        
                    if(this.cameraScroll <= this.projectImages[0].mesh.position.y + (this.camera.heightToFit / 4) || this.project0Y < 100 && this.project0Y >= 0.001)
                    {
                        this.project0Y = this.lerp(this.project0Y, 0, .075)
        
                        gsap.set('.artist .projects .p-0 .p-name .line .word', {
                            y: `${this.project0Y}%`,
                            force3D: true,
                        })
        
                        gsap.set('.artist .projects .p-0 .p-date .line .word', {
                            y: `${this.project0Y}%`,
                            force3D: true,
                            delay: .125
                        })

                        if(document.querySelector('.artist .projects .p-0 .word') && !document.querySelector('.artist .projects .p-0 .word').classList.contains('visible'))
                        {
                            document.querySelectorAll('.artist .projects .p-0 .word').forEach(word =>
                            {
                                word.classList.add('visible')
                            })
                        }
                    }
        
                    if(this.cameraScroll <= this.projectImages[1].mesh.position.y + (this.camera.heightToFit / 4) || this.project1Y < 100 && this.project1Y >= 0.001)
                    {
                        this.project1Y = this.lerp(this.project1Y, 0, .075)
        
                        gsap.set('.artist .projects .p-1 .p-name .line .word', {
                            y: `${this.project1Y}%`,
                            force3D: true,
                        })
        
                        gsap.set('.artist .projects .p-1 .p-date .line .word', {
                            y: `${this.project1Y}%`,
                            force3D: true,
                            delay: .125
                        })

                        if(document.querySelector('.artist .projects .p-1 .word') && !document.querySelector('.artist .projects .p-1 .word').classList.contains('visible'))
                        {
                            document.querySelectorAll('.artist .projects .p-1 .word').forEach(word =>
                            {
                                word.classList.add('visible')
                            })
                        }
                    }
        
                    if(this.cameraScroll <= this.projectImages[2].mesh.position.y + (this.camera.heightToFit / 4) || this.project2Y < 100 && this.project2Y >= 0.001)
                    {
                        this.project2Y = this.lerp(this.project2Y, 0, .075)
        
                        gsap.set('.artist .projects .p-2 .p-name .line .word', {
                            y: `${this.project2Y}%`,
                            force3D: true,
                        })
        
                        gsap.set('.artist .projects .p-2 .p-date .line .word', {
                            y: `${this.project2Y}%`,
                            force3D: true,
                            delay: .125
                        })

                        if(document.querySelector('.artist .projects .p-2 .word') && !document.querySelector('.artist .projects .p-2 .word').classList.contains('visible'))
                        {
                            document.querySelectorAll('.artist .projects .p-2 .word').forEach(word =>
                            {
                                word.classList.add('visible')
                            })
                        }
                    }
        
                    if(this.projectImages[3])
                    {
                        if(this.cameraScroll <= this.projectImages[3].mesh.position.y + (this.camera.heightToFit / 4) || this.project3Y < 100 && this.project3Y >= 0.001)
                        {
                            this.project3Y = this.lerp(this.project3Y, 0, .075)
            
                            gsap.set('.artist .projects .p-3 .p-name .line .word', {
                                y: `${this.project3Y}%`,
                                force3D: true,
                            })
            
                            gsap.set('.artist .projects .p-3 .p-date .line .word', {
                                y: `${this.project3Y}%`,
                                force3D: true,
                                delay: .125
                            })

                            if(document.querySelector('.artist .projects .p-3 .word') && !document.querySelector('.artist .projects .p-3 .word').classList.contains('visible'))
                            {
                                document.querySelectorAll('.artist .projects .p-3 .word').forEach(word =>
                                {
                                    word.classList.add('visible')
                                })
                            }
                        }
                    }
        
                    if(this.cameraScroll <= this.scrollMaxValue + (this.camera.heightToFit / 8) || this.nextArtistTextY < 100 && this.nextArtistTextY >= 0.001)
                    {
                        this.nextArtistTextY = this.lerp(this.nextArtistTextY, 0, .075)
        
                        gsap.set('.artist .next .next-name .line .word', {
                            y: `${this.nextArtistTextY}%`,
                            force3D: true,
                        })
        
                        gsap.set('.artist .next .next-text .line .word', {
                            y: `${this.nextArtistTextY}%`,
                            force3D: true,
                            delay: .125
                        })

                        if(document.querySelector('.artist .next .word') && !document.querySelector('.artist .next .word').classList.contains('visible'))
                        {
                            document.querySelectorAll('.artist .next .word').forEach(word =>
                            {
                                word.classList.add('visible')
                            })
                        }
                    }
                }
            }

            if(this.isOutroStarted && !this.isOutroDone)
            {
                this.portraitImage.material.uniforms.uOpacity.value = this.lerp(this.portraitImage.material.uniforms.uOpacity.value, 0, .125)
                this.coverImage.material.uniforms.uOpacity.value = this.lerp(this.coverImage.material.uniforms.uOpacity.value, 0, .125)
                this.projectImages.forEach(projectImage => 
                {
                    projectImage.material.uniforms.uOpacity.value = this.lerp(projectImage.material.uniforms.uOpacity.value, 0, .125)
                })
                this.nextArtistImage.material.uniforms.uOpacity.value = this.lerp(this.nextArtistImage.material.uniforms.uOpacity.value, 0, .125)
                
                this.outroY = this.lerp(this.outroY, 100, .075)

                gsap.set('.artist .word', {
                    y: this.outroY,
                    force3D: true,
                })  

                if(this.portraitImage.material.uniforms.uOpacity.value <= 0.001)
                {
                    this.isIntroStarted = false
                    this.isIntroDone = false
                    this.isOutroStarted = false
                    this.isOutroDone = true
                    this.linesSplitted = false
                    this.isArtistSet = false

                    this.camera.instance.position.y = -(this.camera.heightToFit / 2)
                    this.cameraScroll = -(this.camera.heightToFit / 2)
                    this.htmlScroll = 0
                    this.htmlScrollValue = 0

                    gsap.set('.artist', {
                        opacity: 1
                    })

                    gsap.set('.artist .word', {
                        y: 100,
                        force3D: true
                    })

                    gsap.set('.artist', {
                        display: 'none'
                    })
                }
            }

            if(this.isTransitionStarted)
            {
                this.portraitImage.material.uniforms.uOpacity.value = this.lerp(this.portraitImage.material.uniforms.uOpacity.value, 0, .125)
                this.coverImage.material.uniforms.uOpacity.value = this.lerp(this.coverImage.material.uniforms.uOpacity.value, 0, .125)
                this.projectImages.forEach(projectImage => 
                {
                    projectImage.material.uniforms.uOpacity.value = this.lerp(projectImage.material.uniforms.uOpacity.value, 0, .125)
                })

                this.nextArtistImage.mesh.scale.x = this.lerp(this.nextArtistImage.mesh.scale.x, this.transitionImageScale.x, .1)
                this.nextArtistImage.mesh.scale.y = this.lerp(this.nextArtistImage.mesh.scale.y, this.transitionImageScale.y, .1)

                if(Math.abs(this.nextArtistImage.mesh.position.x - this.transitionImagePosition.x) <= 0.001)
                {
                    this.isTransitionImagePlaced = true

                    this.cameraScroll = -(this.camera.heightToFit / 2)
                    this.camera.instance.position.y = -(this.camera.heightToFit / 2)
                    this.nextArtistImage.mesh.position.y = -(this.camera.heightToFit / 2)

                    this.htmlScroll = 0
                    this.htmlScrollValue = 0
                    gsap.set('.artist', {
                        y: 0,
                        force3D: true,
                        opacity: 1
                    })

                    this.dispose()

                    this.setHtml()
                    this.setArtist()

                    this.isTransitionStarted = false
                } 
                else 
                {
                    this.nextArtistImage.mesh.position.x = this.lerp(this.nextArtistImage.mesh.position.x, this.transitionImagePosition.x, .1)
                    this.nextArtistImage.mesh.position.y = this.lerp(this.nextArtistImage.mesh.position.y, this.transitionImagePosition.y, .1)

                    this.outroY = this.lerp(this.outroY, 100, .075)

                    gsap.set('.artist .word', {
                        y: this.outroY,
                        force3D: true,
                    })  
                }
            }
        }
    }
}