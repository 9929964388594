import * as THREE from 'three'
import { gsap, CustomEase } from 'gsap/all'
import Split from '../Utils/Split.js'
import Experience from '../Experience.js'
import Image from '../Image.js'
import imageVertexShader from '../Shaders/home/vertex.glsl'
import imageFragmentShader from '../Shaders/home/fragment.glsl'
import Raycaster from '../Utils/Raycaster.js'
import artistsContent from '../artistsContent.js'

export default class Home
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.time = this.experience.time
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeOutCubic', '0.33, 1, 0.68, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        // Setup
        this.isResizing = false
        this.loadOnArtist = false
        this.isMenuOpen = false
        this.isIntroDone = false
        this.isIntroStart = false
        this.isTransitioning = false
        this.isArtistTransitionDone = false
        this.isArtistOpen = false

        this.selectedArtistIndex = 0

        this.navTexts = document.querySelectorAll('nav a span, nav .menu-btn span')

        this.nbOfImages = 14

        this.gridPos = [
            {
                x: 8,
                y: 0
            },
            {
                x: 3,
                y: 0
            },
            {
                x: 11,
                y: 1
            },
            {
                x: 6,
                y: 1
            },
            {
                x: 5,
                y: 1
            },
            {
                x: 0,
                y: 2
            },
            {
                x: 9,
                y: 3
            },
            {
                x: 6,
                y: 3
            },
            {
                x: 11,
                y: 4
            },
            {
                x: 5,
                y: 4
            },
            {
                x: 2,
                y: 4
            },
            {
                x: 7,
                y: 5
            },
            {
                x: 1,
                y: 5
            },
            {
                x: 0,
                y: 5
            },
        ]

        this.artistTextY = [
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
        ]

        this.loaderImageSize = {
            width: (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127) * 4 + ((this.camera.widthToFit - (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127) * 12) / 13) * 3,
            height: ((this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127) * 4 + ((this.camera.widthToFit - (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127) * 12) / 13) * 3) * (14 / 11)
        }

        if(window.innerWidth / window.innerHeight < 1.5)
        {
            this.imageSize = {
                width: (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127),
                height: (this.camera.widthToFit / 100) * (127 / 14.4) 
            }

            document.documentElement.style.setProperty('--column-width', 'calc(1vw * (127 / 14.4) * (95 / 127))')
            document.documentElement.style.setProperty('--grid-image-height', 'calc(1vw * (127 / 14.4))')
            document.documentElement.style.setProperty('--space-between-columns', 'calc((100vw - (1vw * (127 / 14.4) * (95 / 127) * 12)) / 13)')
        }
        else
        {
            this.imageSize = {
                width: (this.camera.heightToFit / 100) * (127 / 9.8) * (95 / 127),
                height: (this.camera.heightToFit / 100) * (127 / 9.8) 
            }

            document.documentElement.style.setProperty('--column-width', 'calc(1vh * (127 / 9.8) * (95 / 127))')
            document.documentElement.style.setProperty('--grid-image-height', 'calc(1vh * (127 / 9.8))')
            document.documentElement.style.setProperty('--space-between-columns', 'calc((100vw - (1vh * (127 / 9.8) * (95 / 127) * 12)) / 13)')
        }

        this.portraitImageSize = {
            width: this.imageSize.width * 4 + ((this.camera.widthToFit - this.imageSize.width * 12) / 13) * 3,
            height: (this.imageSize.width * 4 + ((this.camera.widthToFit - this.imageSize.width * 12) / 13) * 3) * (227 / 185)
        }

        this.images = []
        this.imagesMesh = []
        this.imagesGroup = new THREE.Group()

        for(let i = 0; i < this.nbOfImages; i++)
        {
            this.images[i] = new Image(this.resources.items[`portrait-${i}`], this.loaderImageSize, imageVertexShader, imageFragmentShader)

            this.images[i].material.uniforms.uArtistIndex.value = i

            this.images[i].material.uniforms.uGeometrySize.value.x = this.loaderImageSize.width
            this.images[i].material.uniforms.uGeometrySize.value.y = this.loaderImageSize.height

            this.images[i].mesh.position.x = this.camera.widthToFit / 2
            this.images[i].mesh.position.y = -this.camera.heightToFit / 2

            this.imagesMesh[i] = this.images[i].mesh
        }

        // Artist transition values
        this.artistImageIndex = 0
        this.artistImagePosition = 0
        this.artistImageScale = 0
        this.artistImageGeometrySize = 0

        // Raycaster
        this.raycaster = new Raycaster(this.imagesMesh, this.camera.instance)

        // Mouse
        this.mouse = new THREE.Vector2(0, 0)
        window.addEventListener('mousemove', e => { this.onMouseMove(e) })
    }

    onMouseMove(event) {
        gsap.to(this.mouse, {
            x: -(event.clientX / window.innerWidth) * 2 + 1,
            y: (event.clientY / window.innerHeight) * 2 - 1,
            delay: .05
        })
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    intro()
    {
        this.isIntroStart = true

        gsap.set('.home', {
            display: 'block'
        })

        this.images.forEach(image => 
        {
            image.material.uniforms.uOpacity.value = 1
        })

        Split('lines', '.home .artist-name p')
    }

    artistTransition()
    {
        this.isTransitioning = true
        this.isArtistTransitionDone = false
        this.isArtistOpen = false

        history.pushState({}, '', `/${artistsContent[this.selectedArtistIndex].url}`)
        document.title = `${artistsContent[this.selectedArtistIndex].name}`

        document.body.style.cursor = 'default'

        this.images.forEach((image, index) =>
        {
            if(index !== this.selectedArtistIndex)
            {
                gsap.to(image.material.uniforms.uOpacity, {
                    value: 0,
                    duration: .8,
                    ease: 'easeOutCubic'
                })
            }
            else
            {
                this.artistImageIndex = index

                image.mesh.position.z = 0.001

                this.artistImageScale = {
                    x: this.portraitImageSize.width / this.loaderImageSize.width,
                    y: this.portraitImageSize.height / this.loaderImageSize.height
                }

                this.artistImagePosition = {
                    x: (this.portraitImageSize.width / 2) + ((this.camera.widthToFit - this.imageSize.width * 12) / 13),
                    y: -((this.camera.heightToFit / 2))
                }

                this.artistImageGeometrySize = {
                    x: this.portraitImageSize.width,
                    y: this.portraitImageSize.height
                }
            }
        })
    }

    dispose()
    {
        this.images.forEach(image => 
        {
            image.material.uniforms.uOpacity.value = 0
            image.geometry.dispose()
            image.material.dispose()
            image.textures.dispose()
        })
    }

    resize()
    {
        this.dispose()

        if(window.innerWidth / window.innerHeight < 1.5)
        {
            this.imageSize = {
                width: (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127),
                height: (this.camera.widthToFit / 100) * (127 / 14.4) 
            }

            document.documentElement.style.setProperty('--column-width', 'calc(1vw * (127 / 14.4) * (95 / 127))')
            document.documentElement.style.setProperty('--grid-image-height', 'calc(1vw * (127 / 14.4))')
            document.documentElement.style.setProperty('--space-between-columns', 'calc((100vw - (1vw * (127 / 14.4) * (95 / 127) * 12)) / 13)')
        }
        else
        {
            this.imageSize = {
                width: (this.camera.heightToFit / 100) * (127 / 9.8) * (95 / 127),
                height: (this.camera.heightToFit / 100) * (127 / 9.8) 
            }

            document.documentElement.style.setProperty('--column-width', 'calc(1vh * (127 / 9.8) * (95 / 127))')
            document.documentElement.style.setProperty('--grid-image-height', 'calc(1vh * (127 / 9.8))')
            document.documentElement.style.setProperty('--space-between-columns', 'calc((100vw - (1vh * (127 / 9.8) * (95 / 127) * 12)) / 13)')
        }

        this.portraitImageSize = {
            width: this.imageSize.width * 4 + ((this.camera.widthToFit - this.imageSize.width * 12) / 13) * 3,
            height: (this.imageSize.width * 4 + ((this.camera.widthToFit - this.imageSize.width * 12) / 13) * 3) * (227 / 185)
        }

        this.camera.instance.position.x = this.camera.widthToFit / 2

        for(let i = 0; i < this.nbOfImages; i++)
        {
            this.images[i] = new Image(this.resources.items[`portrait-${i}`], this.loaderImageSize, imageVertexShader, imageFragmentShader)

            this.images[i].mesh.scale.x = this.imageSize.width / this.loaderImageSize.width
            this.images[i].mesh.scale.y = this.imageSize.height / this.loaderImageSize.height

            this.images[i].material.uniforms.uArtistIndex.value = i

            this.images[i].material.uniforms.uGeometrySize.value.x = this.imageSize.width
            this.images[i].material.uniforms.uGeometrySize.value.y = this.imageSize.height

            this.images[i].mesh.position.x = (this.imageSize.width / 2) + ((this.camera.widthToFit - this.imageSize.width * 12) / 13) + ((this.camera.widthToFit - this.imageSize.width * 12) / 13 + this.imageSize.width) * this.gridPos[i].x
            // this.images[i].mesh.position.y = -(this.camera.heightToFit - (this.imageSize.height / 2) - (((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * this.gridPos[i].y) - ((this.camera.heightToFit / 100) * (425 / 128)))
            this.images[i].mesh.position.y = -((this.imageSize.height / 2) + (this.camera.heightToFit / 100 * 7.5) + (this.camera.widthToFit / 100 * 1.25) + ((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * 5 - ((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * this.gridPos[i].y)

            this.imagesMesh[i] = this.images[i].mesh
        }

        this.raycaster = new Raycaster(this.imagesMesh, this.camera.instance)
    }

    update()
    {
        if(!this.isResizing)
        {
            if(this.isIntroStart && !this.isIntroDone)
            {
                this.images.forEach((image, index) => 
                {
                    image.mesh.scale.x = this.lerp(image.mesh.scale.x, this.imageSize.width / this.loaderImageSize.width, .1)
                    image.mesh.scale.y = this.lerp(image.mesh.scale.y, this.imageSize.height / this.loaderImageSize.height, .1)
    
                    image.material.uniforms.uGeometrySize.value.x = this.lerp(image.material.uniforms.uGeometrySize.value.x, this.imageSize.width, .1)
                    image.material.uniforms.uGeometrySize.value.y = this.lerp(image.material.uniforms.uGeometrySize.value.y, this.imageSize.height, .1)
    
                    image.mesh.position.x = this.lerp(image.mesh.position.x, (this.imageSize.width / 2) + ((this.camera.widthToFit - this.imageSize.width * 12) / 13) + ((this.camera.widthToFit - this.imageSize.width * 12) / 13 + this.imageSize.width) * this.gridPos[index].x, .1)
                    // image.mesh.position.y = this.lerp(image.mesh.position.y, -(this.camera.heightToFit - (this.imageSize.height / 2) - (((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * this.gridPos[index].y) - ((this.camera.heightToFit / 100) * (425 / 128))), .1)
                    image.mesh.position.y = this.lerp(image.mesh.position.y, image.mesh.position.y = -((this.imageSize.height / 2) + (this.camera.heightToFit / 100 * 7.5) + (this.camera.widthToFit / 100 * 1.25) + ((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * 5 - ((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * this.gridPos[index].y), .1)
    
                    if(Math.abs(image.mesh.scale.x - (this.imageSize.width / this.loaderImageSize.width)) <= 0.001)
                    {
                        this.isIntroDone = true
                    }
                })
            }
    
            if(this.raycaster && this.isIntroDone && !this.loadOnArtist && !this.isMenuOpen)
            {
                this.raycaster.update()
    
                if(!this.raycaster.objectClicked && !this.isTransitioning && !this.isArtistOpen)
                {
                    this.images.forEach((image, index) =>
                    {
                        if(this.raycaster.currentIntersect)
                        {
                            if(image.mesh !== this.raycaster.currentIntersect.object)
                            {
                                image.material.uniforms.uOpacity.value = this.lerp(image.material.uniforms.uOpacity.value, .3, .05)
    
                                this.artistTextY[Math.abs(index - 13)] = this.lerp(this.artistTextY[Math.abs(index - 13)], 100, .1)
    
                                gsap.set(document.querySelectorAll('.home .artist-name')[Math.abs(index - 13)].children[0].children[0].children, {
                                    y: `${this.artistTextY[Math.abs(index - 13)]}%`
                                })
    
                                gsap.set(document.querySelectorAll('.home .artist-name')[Math.abs(index - 13)].children[1].children[0].children, {
                                    y: `${this.artistTextY[Math.abs(index - 13)]}%`
                                })
                            }
                            else
                            {
                                image.material.uniforms.uOpacity.value = this.lerp(image.material.uniforms.uOpacity.value, 1, .05)
    
                                this.artistTextY[Math.abs(index - 13)] = this.lerp(this.artistTextY[Math.abs(index - 13)], 0, .1)
    
                                gsap.set(document.querySelectorAll('.home .artist-name')[Math.abs(index - 13)].children[0].children[0].children, {
                                    y: `${this.artistTextY[Math.abs(index - 13)]}%`
                                })
    
                                gsap.set(document.querySelectorAll('.home .artist-name')[Math.abs(index - 13)].children[1].children[0].children, {
                                    y: `${this.artistTextY[Math.abs(index - 13)]}%`,
                                    delay: .125
                                })
                            }
    
                            document.body.style.cursor = 'pointer'
                        }
                        else
                        {
                            image.material.uniforms.uOpacity.value = this.lerp(image.material.uniforms.uOpacity.value, 1, .05)
    
                            this.artistTextY[Math.abs(index - 13)] = this.lerp(this.artistTextY[Math.abs(index - 13)], 100, .1)
    
                            gsap.set(document.querySelectorAll('.home .artist-name')[Math.abs(index - 13)].children[0].children[0].children, {
                                y: `${this.artistTextY[Math.abs(index - 13)]}%`
                            })
    
                            gsap.set(document.querySelectorAll('.home .artist-name')[Math.abs(index - 13)].children[1].children[0].children, {
                                y: `${this.artistTextY[Math.abs(index - 13)]}%`
                            })
    
                            document.body.style.cursor = 'default'
                        }
                    })
                }
    
                if(this.raycaster.currentIntersect && !this.isTransitioning && !this.isArtistOpen)
                {
                    this.selectedArtistIndex = this.raycaster.currentIntersect.object.material.uniforms.uArtistIndex.value
                }
    
                if(this.raycaster.objectClicked)
                {
                    this.artistTextY[Math.abs(this.selectedArtistIndex - 13)] = this.lerp(this.artistTextY[Math.abs(this.selectedArtistIndex - 13)], 100, .1)
    
                    gsap.set(document.querySelectorAll('.home .artist-name')[Math.abs(this.selectedArtistIndex - 13)].children[0].children[0].children, {
                        y: `${this.artistTextY[Math.abs(this.selectedArtistIndex - 13)]}%`
                    })
    
                    gsap.set(document.querySelectorAll('.home .artist-name')[Math.abs(this.selectedArtistIndex - 13)].children[1].children[0].children, {
                        y: `${this.artistTextY[Math.abs(this.selectedArtistIndex - 13)]}%`
                    })
                    
                    if(this.artistTextY[Math.abs(this.selectedArtistIndex - 13)] >= 90)
                    {
                        this.artistTransition()
                        this.raycaster.objectClicked = null
                    }
                }
    
                if(this.isTransitioning && !this.isArtistTransitionDone && !this.isArtistOpen)
                {
                    this.images[this.artistImageIndex].mesh.scale.x = this.lerp(this.images[this.artistImageIndex].mesh.scale.x, this.artistImageScale.x, .125)
                    this.images[this.artistImageIndex].mesh.scale.y = this.lerp(this.images[this.artistImageIndex].mesh.scale.y, this.artistImageScale.y, .125)
                    
                    this.images[this.artistImageIndex].mesh.position.x = this.lerp(this.images[this.artistImageIndex].mesh.position.x, this.artistImagePosition.x, .125)
                    this.images[this.artistImageIndex].mesh.position.y = this.lerp(this.images[this.artistImageIndex].mesh.position.y, this.artistImagePosition.y, .125)
                    
                    this.images[this.artistImageIndex].material.uniforms.uGeometrySize.value.x = this.lerp(this.images[this.artistImageIndex].material.uniforms.uGeometrySize.value.x, this.artistImageGeometrySize.x, .125)
                    this.images[this.artistImageIndex].material.uniforms.uGeometrySize.value.y = this.lerp(this.images[this.artistImageIndex].material.uniforms.uGeometrySize.value.y, this.artistImageGeometrySize.y, .125)
    
                    if(Math.abs(this.images[this.artistImageIndex].mesh.scale.x - this.artistImageScale.x) <= 0.001)
                    {
                        this.isTransitioning = false
                        this.isArtistTransitionDone = true
                        this.isArtistOpen = true
    
                        this.images[this.artistImageIndex].material.uniforms.uOpacity.value = 0
    
                        this.images[this.artistImageIndex].mesh.scale.x = this.imageSize.width / this.loaderImageSize.width
                        this.images[this.artistImageIndex].mesh.scale.y = this.imageSize.height / this.loaderImageSize.height
    
                        this.images[this.artistImageIndex].mesh.position.z = 0
    
                        this.images[this.artistImageIndex].material.uniforms.uGeometrySize.value.x = this.imageSize.width
                        this.images[this.artistImageIndex].material.uniforms.uGeometrySize.value.y = this.imageSize.height
                        
                        this.images[this.artistImageIndex].mesh.position.x = (this.imageSize.width / 2) + ((this.camera.widthToFit - this.imageSize.width * 12) / 13) + ((this.camera.widthToFit - this.imageSize.width * 12) / 13 + this.imageSize.width) * this.gridPos[this.artistImageIndex].x
                        this.images[this.artistImageIndex].mesh.position.y = -((this.imageSize.height / 2) + (this.camera.heightToFit / 100 * 7.5) + (this.camera.widthToFit / 100 * 1.25) + ((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * 5 - ((this.camera.heightToFit / 100) * (125 / 64) + this.imageSize.height) * this.gridPos[this.artistImageIndex].y)
                    }
                }
            }
        }
    }
}