import * as THREE from 'three'
import { gsap, CustomEase } from 'gsap/all'
import Experience from '../Experience.js'
import Image from '../Image.js'
import imageVertexShader from '../Shaders/loader/vertex.glsl'
import imageFragmentShader from '../Shaders/loader/fragment.glsl'
import Raycaster from '../Utils/Raycaster.js'

export default class Loader
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.time = this.experience.time
        gsap.registerPlugin(CustomEase)
        CustomEase.create('easeOutCubic', '0.33, 1, 0.68, 1')
        CustomEase.create('easeOutExpo', '0.16, 1, 0.3, 1')

        // Setup
        this.isIntroDone = false
        this.isHomeIntroDone = false

        this.texts = document.querySelectorAll('.loader .text-mask p')

        this.nbOfImages = 14

        this.gridPos = [
            {
                x: 0,
                y: 5
            },
            {
                x: 1,
                y: 5
            },
            {
                x: 7,
                y: 5
            },
            {
                x: 2,
                y: 4
            },
            {
                x: 5,
                y: 4
            },
            {
                x: 11,
                y: 4
            },
            {
                x: 6,
                y: 3
            },
            {
                x: 9,
                y: 3
            },
            {
                x: 0,
                y: 2
            },
            {
                x: 5,
                y: 1
            },
            {
                x: 6,
                y: 1
            },
            {
                x: 11,
                y: 1
            },
            {
                x: 3,
                y: 0
            },
            {
                x: 8,
                y: 0
            },
        ]

        this.gridPos.reverse()

        this.imageSize = {
            width: (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127) * 4 + ((this.camera.widthToFit - (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127) * 12) / 13) * 3,
            height: ((this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127) * 4 + ((this.camera.widthToFit - (this.camera.widthToFit / 100) * (127 / 14.4) * (95 / 127) * 12) / 13) * 3) * (14 / 11)
        }

        this.images = []

        for(let i = 0; i < this.nbOfImages; i++)
        {
            this.images[i] = new Image(this.resources.items[`portrait-${i}`], this.imageSize, imageVertexShader, imageFragmentShader)

            this.images[i].material.uniforms.uGeometrySize.value = new THREE.Vector2(((window.innerHeight / 100) * (3175 / 256) * (95 / 127) * 4 + ((window.innerWidth - (window.innerHeight / 100) * (3175 / 256) * (95 / 127) * 12) / 13) * 3) * (14 / 11) * (this.resources.items[`portrait-${i}`].image.width / this.resources.items[`portrait-${i}`].image.height), ((window.innerHeight / 100) * (3175 / 256) * (95 / 127) * 4 + ((window.innerWidth - (window.innerHeight / 100) * (3175 / 256) * (95 / 127) * 12) / 13) * 3) * (14 / 11))

            this.images[i].material.uniforms.uOpacity.value = 1
            this.images[i].mesh.position.x = this.camera.widthToFit / 2
            this.images[i].mesh.position.y = -this.camera.heightToFit / 2
        }

        // Raycaster
        this.raycaster = new Raycaster(this.imagesMesh, this.camera.instance)
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    intro()
    {
        this.texts.forEach((text, index) =>
        {
            gsap.to(text, {
                y: 0,
                duration: .8,
                ease: 'easeOutCubic',
                delay: index * (.7 / 2) + .5
            })
        })

        this.images.forEach((image, index) =>
        {
            gsap.to(image.material.uniforms.uSelected, {
                value: 1,
                duration: 1.5,
                ease: 'easeOutExpo',
                delay: index * (1.5 / (this.nbOfImages - 1)) + .5,
                onComplete: () =>
                {
                    if(index === this.nbOfImages - 1)
                    {
                        this.isIntroDone = true

                        gsap.to(this.texts, {
                            y: '-100%',
                            duration: .8,
                            ease: 'easeOutCubic'
                        })

                        this.images.forEach(image => {
                            image.material.uniforms.uOpacity.value = 0
                        })
                    }
                }
            })
        })
    }

    update()
    {
    }
}