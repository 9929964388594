
import Experience from './Experience/Experience.js'

if(window.navigator.userAgent.match(/Android/i)
|| window.navigator.userAgent.match(/webOS/i)
|| window.navigator.userAgent.match(/iPhone/i)
|| window.navigator.userAgent.match(/iPod/i)
|| window.navigator.userAgent.match(/iPad/i)
|| window.navigator.userAgent.match(/BlackBerry/i)
|| window.navigator.userAgent.match(/Windows Phone/i))
{
    const experience = new Experience(document.querySelector('.mobile canvas.webgl'), true)
}
else
{
    const experience = new Experience(document.querySelector('.desktop canvas.webgl'), false)
}