export default [
    {
        url: `susan-kare`,
        name: `Susan Kare`,
        job: `Graphic designer`,
        description: `<div>Susan Kare is an American graphic designer and computer iconographer, born in 1954. She is best known for designing the original icons and fonts for the Apple Macintosh computer in the 1980s. These included the famous smiling Macintosh computer icon, the trash can, and the fonts Chicago and Geneva.</div>`,
        secondDescription: `<div>Kare also worked on the early interface designs for Microsoft Windows and Facebook. She has received numerous awards for her contributions to design and has been inducted into the Visual Design Hall of Fame. Today, Kare continues to work as a designer and artist, creating digital and physical products.</div>`,
        projectName0: `Apple Icon`,
        projectDate0: `1982`,
        projectName1: `Windows card game`,
        projectDate1: `1990`,
        projectName2: `Apple Hello`,
        projectDate2: `1984`
    },
    {
        url: `saul-bass`,
        name: `Saul Bass`,
        job: `Graphic designer`,
        description: `<div>Saul Bass (1920‑1996) was an American graphic designer and filmmaker, widely considered to be one of the greatest designers of the 20th century. He is best known for his innovative title sequences for films, including Alfred Hitchcock's Vertigo and North by Northwest.</div>`,
        secondDescription: `<div>Bass's designs were characterized by their simplicity, boldness, and use of strong geometric shapes and vivid colors. He also designed iconic logos for many major companies, such as AT&T, United Airlines, and Quaker Oats. Bass received numerous awards for his work, including an Academy Award for his short film Why Man Creates. His legacy continues to influence and inspire designers today.</div>`,
        projectName0: `United Airlines`,
        projectDate0: `1973`,
        projectName1: `AT&T`,
        projectDate1: `1969`,
        projectName2: `Minolta`,
        projectDate2: `1972`,
        projectName3: `Warner Communications`,
        projectDate3: `1972`
    },
    {
        url: `paula-scher`,
        name: `Paula Scher`,
        job: `Graphic designer`,
        description: `<div>Paula Scher is an American graphic designer and artist, born in 1948. She is best known for her innovative and eclectic approach to design, which combines typography, imagery, and color in unexpected ways. Scher began her career as a record cover designer, creating iconic album covers for musicians such as Bob Dylan and Aerosmith. She later became a partner at the design firm Pentagram, where she worked on branding and identity projects for clients such as Citibank, The Public Theater, and Microsoft Windows.</div>`,
        secondDescription: `<div>Scher has received numerous awards for her work, including the AIGA Medal and the National Design Award for Communication Design. She continues to work as a designer and artist, and her work is featured in museums and galleries around the world.</div>`,
        projectName0: `Windows`,
        projectDate0: `2012`,
        projectName1: `Tiffany & Co`,
        projectDate1: `2003`,
        projectName2: `MoMA`,
        projectDate2: `2004`
    },
    {
        url: `massimo-vignelli`,
        name: `Massimo Vignelli`,
        job: `Graphic designer`,
        description: `<div>Massimo Vignelli (1931‑2014) was an Italian designer who worked in a variety of fields, including graphic design, product design, and architecture. He was known for his minimalist and elegant designs, which often featured a limited color palette and simple geometric shapes.</div><div>Vignelli's work includes iconic designs such as the American Airlines logo, the New York City subway map, and the Bloomingdale's shopping bag. He was a proponent of the modernist design movement, which emphasized simplicity, clarity, and functionality, and he believed that good design should be accessible to everyone.</div>`,
        secondDescription: `<div>In addition to his work as a designer, Vignelli was also a teacher and author. He taught at the Yale School of Art and the School of Visual Arts in New York City, and he wrote several books on design, including "The Vignelli Canon", which is widely regarded as a classic in the field. Vignelli's influence can still be seen in design today, and he is remembered as one of the most important and influential designers of the 20th century.</div>`,
        projectName0: `American Airlines`,
        projectDate0: `1967`,
        projectName1: `New York City Subway Map`,
        projectDate1: `1972`,
        projectName2: `Bloomingdale's Shopping Bag`,
        projectDate2: `1973`,
        projectName3: `Stendig Calendar`,
        projectDate3: `1966`
    },
    {
        url: `jessica-walsh`,
        name: `Jessica Walsh`,
        job: `Graphic designer`,
        description: `<div>Jessica Walsh is a multi‑talented designer, art director, and entrepreneur based in New York City. She co‑founded the design and branding agency Sagmeister & Walsh with renowned graphic designer Stefan Sagmeister, where they worked on projects for clients such as The New York Times, Adobe, and Levi's.</div><div>In addition to her work with Sagmeister & Walsh, Walsh has also worked on personal projects and collaborations. One of her most well‑known projects is "40 Days of Dating," which she co‑created with Timothy Goodman. The project, which chronicled the real‑life dating experiment of Walsh and Goodman, became an internet sensation and was later turned into a book.</div>`,
        secondDescription: `<div>Walsh has also been recognized for her contributions to the design industry. She has been named one of Forbes' 30 Under 30 in Art and Design, and her work has been recognized by organizations such as the AIGA and the Type Directors Club. She is also an advocate for women in design, and has spoken publicly about the importance of promoting diversity and inclusion in the industry.</div>`,
        projectName0: `Jow Branding`,
        projectDate0: `2022`,
        projectName1: `Milly: Color Lover`,
        projectDate1: `2018`,
        projectName2: `The NYT Magazine: Donald Trump`,
        projectDate2: `2019`
    },
    {
        url: `charlotte-perriand`,
        name: `Charlotte Perriand`,
        job: `Furniture designer`,
        description: `<div>Charlotte Perriand (1903‑1999) was a French architect, designer, and urban planner. She is widely regarded as one of the most influential designers of the 20th century and a pioneer of modernist design. Perriand began her career as a furniture designer and collaborated with the likes of Le Corbusier and Pierre Jeanneret in the 1920s and 1930s. Her designs were characterized by their simplicity, functionality, and use of new materials such as steel and aluminum.</div>`,
        secondDescription: `<div>Perriand's work was instrumental in shaping the modernist movement, and she continued to work as an architect and urban planner throughout her career. She received numerous awards for her contributions to design and was posthumously inducted into the Interior Design Hall of Fame.</div>`,
        projectName0: `Chaise longue B306`,
        projectDate0: `1928 / 1932`,
        projectName1: `Bibliothèque "Tunisie"`,
        projectDate1: `1952`,
        projectName2: `Table extensible "De Luxe"`,
        projectDate2: `1930`
    },
    {
        url: `marcel-breuer`,
        name: `Marcel Breuer`,
        job: `Furniture designer`,
        description: `<div>Marcel Breuer (1902‑1981) was a Hungarian‑born modernist architect and designer, best known for his innovative furniture designs. He was a key figure in the Bauhaus movement and worked closely with Walter Gropius, the founder of the Bauhaus school. Breuer's furniture designs were characterized by their use of new materials, such as tubular steel and plywood, and their emphasis on function over ornamentation. Some of his most famous furniture designs include the Wassily Chair and the Cesca Chair.</div>`,
        secondDescription: `<div>Breuer also worked on a number of architectural projects throughout his career, including the UNESCO Headquarters in Paris and the Whitney Museum of American Art in New York City. His work has had a profound influence on modernist design and continues to be celebrated today.</div>`,
        projectName0: `Wassily Chair`,
        projectDate0: `1925`,
        projectName1: `Ottoman S 35 LH`,
        projectDate1: `1930`,
        projectName2: `Isokon Long Chair`,
        projectDate2: `1935 / 1936`
    },
    {
        url: `arne-jacobsen`,
        name: `Arne Jacobsen`,
        job: `Furniture designer`,
        description: `<div>Arne Jacobsen (1902‑1971) was a Danish architect and designer, known for his contributions to modernist design. He studied architecture at the Royal Danish Academy of Fine Arts and later worked as a professor of architecture there. Jacobsen is perhaps best known for his furniture designs, including the iconic Egg Chair, Swan Chair, and Series 7 Chair.</div>`,
        secondDescription: `<div>He was also a prolific architect, designing buildings such as the SAS Royal Hotel in Copenhagen and St. Catherine's College in Oxford. Jacobsen's designs were characterized by their clean lines, simple forms, and functionalist approach. He is considered one of the most important modernist designers and architects of the 20th century, and his work continues to influence designers today.</div>`,
        projectName0: `The Egg Chair`,
        projectDate0: `1959`,
        projectName1: `The Swan Chair`,
        projectDate1: `1958`,
        projectName2: `The AJ Floor Lamp`,
        projectDate2: `1960`
    },
    {
        url: `phillipe-starck`,
        name: `Phillipe Starck`,
        job: `Furniture designer`,
        description: `<div>Philippe Starck is a French designer known for his contemporary and sometimes whimsical designs, including furniture pieces such as the Louis Ghost Chair and the Costes Chair. He has also designed a range of other products, including household items and high‑profile hotel and restaurant projects. Starck is known for his bold and innovative designs, willingness to experiment with new materials, and focus on creating functional and visually striking products.</div>`,
        secondDescription: `<div>Starck's furniture designs often feature clean lines and geometric shapes, and he is known for his use of unconventional materials such as polycarbonate and aluminum. He has also designed a range of other products, from toothbrushes to motorbikes, and has collaborated with many well‑known brands. In addition to his design work, Starck has been involved in various philanthropic projects, including the creation of a low‑cost housing project in Montpellier, France, and the development of a line of sustainable home goods. His innovative designs and philanthropic efforts have earned him numerous awards and accolades throughout his career.</div>`,
        projectName0: `The Louis Ghost Chair`,
        projectDate0: `2002`,
        projectName1: `The Costes Chair`,
        projectDate1: `1984`,
        projectName2: `The Juicy Salif Citrus Squeezer`,
        projectDate2: `1988`,
        projectName3: `The Masters Chair`,
        projectDate3: `2005`
    },
    {
        url: `ray-eames`,
        name: `Ray Eames`,
        job: `Furniture designer`,
        description: `<div>Ray Eames was an American artist, designer, and filmmaker who, along with her husband Charles Eames, was responsible for some of the most iconic furniture designs of the 20th century. Ray and Charles Eames worked together in the Eames Office, which they founded in 1941 in Los Angeles, California. They collaborated on a wide range of projects, including furniture, architecture, graphic design, photography, and film.</div>`,
        secondDescription: `<div>Ray Eames was born as Bernice Alexandra Kaiser on December 15, 1912, in Sacramento, California, and passed away on August 21, 1988, in Los Angeles, California. She met Charles Eames in 1940, and the two were married in 1941. Together, they designed some of the most innovative furniture of the mid‑20th century, including the Eames Lounge Chair and Ottoman, the Eames Molded Plywood Chair, and the Eames Aluminum Group. Their designs were characterized by their innovative use of materials, their focus on comfort and function, and their elegant simplicity.</div>`,
        projectName0: `Eames Lounge Chair and Ottoman`,
        projectDate0: `1956`,
        projectName1: `Eames Molded Plywood Chair`,
        projectDate1: `1946 / 1947`,
        projectName2: `Eames Wire Chair`,
        projectDate2: `1951`
    },
    {
        url: `ansel-adams`,
        name: `Ansel Adams`,
        job: `Photographer`,
        description: `<div>Ansel Adams was an American photographer known for his black‑and‑white landscape photographs of the American West. He was born on February 20, 1902, in San Francisco, California, and began taking photographs as a child. He studied photography in both high school and college, and became a full‑time photographer in 1930.</div><div>Adams is best known for his stunning images of Yosemite National Park, which he first visited in 1916 and returned to many times throughout his life. He developed a unique style of photography, characterized by sharp focus, bold contrasts, and a deep appreciation for the natural beauty of the landscape.</div>`,
        secondDescription: `<div>In addition to his photographic work, Adams was also a conservationist and advocate for the protection of the environment. He was a founding member of the Sierra Club and used his photography to raise awareness about the need to preserve America's natural landscapes.</div><div>Adams passed away on April 22, 1984, leaving behind a legacy as one of the most celebrated photographers of all time. His work continues to inspire photographers and art enthusiasts around the world, and his contributions to the field of photography are widely recognized and admired.</div>`,
        projectName0: `"Moonrise, Hernandez, New Mexico"`,
        projectDate0: `1941`,
        projectName1: `"The Tetons and the Snake River"`,
        projectDate1: `1942`,
        projectName2: `"Clearing Winter Storm, Yosemite National Park"`,
        projectDate2: `1944`,
        projectName3: `"Half Dome, Merced River, Winter"`,
        projectDate3: `1938`
    },
    {
        url: `dorothea-lange`,
        name: `Dorothea Lange`,
        job: `Photographer`,
        description: `<div>Dorothea Lange (1895‑1965) was an American photographer best known for her work documenting the effects of the Great Depression on the lives of ordinary Americans. Lange worked for the Farm Security Administration (FSA) during the 1930s, producing some of her most iconic photographs during this time. Her images of Dust Bowl migrants, sharecroppers, and other struggling Americans helped to raise awareness of the poverty and suffering that many Americans were experiencing during this time.</div>`,
        secondDescription: `<div>Lange's photographs were characterized by their raw and intimate portrayal of her subjects, many of whom were facing extreme hardship. Her most famous photograph, "Migrant Mother," taken in 1936, became a symbol of the Great Depression and remains one of the most recognizable images of the era.</div><div>Lange continued to work as a photographer throughout her life, and her later work focused on social justice issues, such as the internment of Japanese Americans during World War II. Her work has had a profound impact on documentary photography and continues to be celebrated today for its power and empathy.</div>`,
        projectName0: `From Texas Farmer to Migratory Worker`,
        projectDate0: `1938`,
        projectName1: `Migrant Mother`,
        projectDate1: `1936`,
        projectName2: `Job Seekers`,
        projectDate2: `1950s`
    },
    {
        url: `cindy-sherman`,
        name: `Cindy Sherman`,
        job: `Photographer`,
        description: `<div>Cindy Sherman (born January 19, 1954) is an American photographer and artist known for her self‑portraits that explore the construction of identity, gender roles, and the representation of women in art and media. Sherman's photographs often depict herself in a variety of different personas, including stereotypical female archetypes, such as the housewife, the movie star, and the seductress.</div>`,
        secondDescription: `<div>Sherman gained international recognition in the 1980s with her series "Untitled Film Stills," which featured photographs of herself in various cinematic roles, evoking the film noir and B‑movie genres of the 1950s and 60s. Sherman's work has been exhibited in galleries and museums around the world and has been the subject of numerous retrospectives.</div><div>In addition to her photography, Sherman has also worked in film and video, creating works that explore similar themes of identity and representation. She is widely regarded as one of the most influential artists of her generation, and her work continues to inspire and challenge audiences today.</div>`,
        projectName0: `Untitled Film Stills`,
        projectDate0: `1977`,
        projectName1: `Untitled (Marilyn)`,
        projectDate1: `1982`,
        projectName2: `Untitled #96`,
        projectDate2: `1981`
    },
    {
        url: `robert-capa`,
        name: `Robert Capa`,
        job: `Photographer`,
        description: `<div>Robert Capa (1913‑1954) was a Hungarian‑born American photojournalist and war photographer. Capa began his career as a freelance photographer in Paris in the 1930s, and his work quickly gained international recognition. He is best known for his photographs of the Spanish Civil War, which he covered extensively from 1936‑1939. Capa's images of the war, including his iconic photograph "The Falling Soldier," are considered some of the most powerful and influential war photographs ever taken.</div>`,
        secondDescription: `<div>Capa also covered World War II, documenting key moments such as the D‑Day landings and the liberation of Paris. He was known for his willingness to take risks to get the shot, and his images often captured the human cost of war in a way that was both raw and empathetic. Capa co‑founded the Magnum Photos agency in 1947, along with Henri Cartier‑Bresson and other prominent photographers, and continued to work as a photographer until his death in 1954 while covering the First Indochina War. His work has had a profound influence on photojournalism and war photography, and he is widely considered one of the greatest photographers of the 20th century.</div>`,
        projectName0: `Leon Trotsky lecturing in Copenhagen`,
        projectDate0: `1932`,
        projectName1: `The first wave of American troops landing on D‑Day`,
        projectDate1: `1944`,
        projectName2: `Montblanch`,
        projectDate2: `1938`
    }
]