export default [
    {
        name: 'portrait-0',
        type: 'texture',
        path: 'textures/Portrait/portrait-0.webp'
    },
    {
        name: 'portrait-1',
        type: 'texture',
        path: 'textures/Portrait/portrait-1.webp'
    },
    {
        name: 'portrait-2',
        type: 'texture',
        path: 'textures/Portrait/portrait-2.webp'
    },
    {
        name: 'portrait-3',
        type: 'texture',
        path: 'textures/Portrait/portrait-3.webp'
    },
    {
        name: 'portrait-4',
        type: 'texture',
        path: 'textures/Portrait/portrait-4.webp'
    },
    {
        name: 'portrait-5',
        type: 'texture',
        path: 'textures/Portrait/portrait-5.webp'
    },
    {
        name: 'portrait-6',
        type: 'texture',
        path: 'textures/Portrait/portrait-6.webp'
    },
    {
        name: 'portrait-7',
        type: 'texture',
        path: 'textures/Portrait/portrait-7.webp'
    },
    {
        name: 'portrait-8',
        type: 'texture',
        path: 'textures/Portrait/portrait-8.webp'
    },
    {
        name: 'portrait-9',
        type: 'texture',
        path: 'textures/Portrait/portrait-9.webp'
    },
    {
        name: 'portrait-10',
        type: 'texture',
        path: 'textures/Portrait/portrait-10.webp'
    },
    {
        name: 'portrait-11',
        type: 'texture',
        path: 'textures/Portrait/portrait-11.webp'
    },
    {
        name: 'portrait-12',
        type: 'texture',
        path: 'textures/Portrait/portrait-12.webp'
    },
    {
        name: 'portrait-13',
        type: 'texture',
        path: 'textures/Portrait/portrait-13.webp'
    },
    {
        name: 'portrait-normal-0',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-0.webp'
    },
    {
        name: 'portrait-normal-1',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-1.webp'
    },
    {
        name: 'portrait-normal-2',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-2.webp'
    },
    {
        name: 'portrait-normal-3',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-3.webp'
    },
    {
        name: 'portrait-normal-4',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-4.webp'
    },
    {
        name: 'portrait-normal-5',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-5.webp'
    },
    {
        name: 'portrait-normal-6',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-6.webp'
    },
    {
        name: 'portrait-normal-7',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-7.webp'
    },
    {
        name: 'portrait-normal-8',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-8.webp'
    },
    {
        name: 'portrait-normal-9',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-9.webp'
    },
    {
        name: 'portrait-normal-10',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-10.webp'
    },
    {
        name: 'portrait-normal-11',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-11.webp'
    },
    {
        name: 'portrait-normal-12',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-12.webp'
    },
    {
        name: 'portrait-normal-13',
        type: 'texture',
        path: 'textures/Portrait/portrait-normal-13.webp'
    },
    {
        name: 'cover-0',
        type: 'texture',
        path: 'textures/Cover/cover-0.webp'
    },
    {
        name: 'cover-1',
        type: 'texture',
        path: 'textures/Cover/cover-1.webp'
    },
    {
        name: 'cover-2',
        type: 'texture',
        path: 'textures/Cover/cover-2.webp'
    },
    {
        name: 'cover-3',
        type: 'texture',
        path: 'textures/Cover/cover-3.webp'
    },
    {
        name: 'cover-4',
        type: 'texture',
        path: 'textures/Cover/cover-4.webp'
    },
    {
        name: 'cover-5',
        type: 'texture',
        path: 'textures/Cover/cover-5.webp'
    },
    {
        name: 'cover-6',
        type: 'texture',
        path: 'textures/Cover/cover-6.webp'
    },
    {
        name: 'cover-7',
        type: 'texture',
        path: 'textures/Cover/cover-7.webp'
    },
    {
        name: 'cover-8',
        type: 'texture',
        path: 'textures/Cover/cover-8.webp'
    },
    {
        name: 'cover-9',
        type: 'texture',
        path: 'textures/Cover/cover-9.webp'
    },
    {
        name: 'cover-10',
        type: 'texture',
        path: 'textures/Cover/cover-10.webp'
    },
    {
        name: 'cover-11',
        type: 'texture',
        path: 'textures/Cover/cover-11.webp'
    },
    {
        name: 'cover-12',
        type: 'texture',
        path: 'textures/Cover/cover-12.webp'
    },
    {
        name: 'cover-13',
        type: 'texture',
        path: 'textures/Cover/cover-13.webp'
    },
    {
        name: 'project-0-0',
        type: 'texture',
        path: 'textures/Projects/0/project-0-0.webp'
    },
    {
        name: 'project-0-1',
        type: 'texture',
        path: 'textures/Projects/0/project-0-1.webp'
    },
    {
        name: 'project-0-2',
        type: 'texture',
        path: 'textures/Projects/0/project-0-2.webp'
    },
    {
        name: 'project-1-0',
        type: 'texture',
        path: 'textures/Projects/1/project-1-0.webp'
    },
    {
        name: 'project-1-1',
        type: 'texture',
        path: 'textures/Projects/1/project-1-1.webp'
    },
    {
        name: 'project-1-2',
        type: 'texture',
        path: 'textures/Projects/1/project-1-2.webp'
    },
    {
        name: 'project-1-3',
        type: 'texture',
        path: 'textures/Projects/1/project-1-3.webp'
    },
    {
        name: 'project-2-0',
        type: 'texture',
        path: 'textures/Projects/2/project-2-0.webp'
    },
    {
        name: 'project-2-1',
        type: 'texture',
        path: 'textures/Projects/2/project-2-1.webp'
    },
    {
        name: 'project-2-2',
        type: 'texture',
        path: 'textures/Projects/2/project-2-2.webp'
    },
    {
        name: 'project-3-0',
        type: 'texture',
        path: 'textures/Projects/3/project-3-0.webp'
    },
    {
        name: 'project-3-1',
        type: 'texture',
        path: 'textures/Projects/3/project-3-1.webp'
    },
    {
        name: 'project-3-2',
        type: 'texture',
        path: 'textures/Projects/3/project-3-2.webp'
    },
    {
        name: 'project-3-3',
        type: 'texture',
        path: 'textures/Projects/3/project-3-3.webp'
    },
    {
        name: 'project-4-0',
        type: 'texture',
        path: 'textures/Projects/4/project-4-0.webp'
    },
    {
        name: 'project-4-1',
        type: 'texture',
        path: 'textures/Projects/4/project-4-1.webp'
    },
    {
        name: 'project-4-2',
        type: 'texture',
        path: 'textures/Projects/4/project-4-2.webp'
    },
    {
        name: 'project-5-0',
        type: 'texture',
        path: 'textures/Projects/5/project-5-0.webp'
    },
    {
        name: 'project-5-1',
        type: 'texture',
        path: 'textures/Projects/5/project-5-1.webp'
    },
    {
        name: 'project-5-2',
        type: 'texture',
        path: 'textures/Projects/5/project-5-2.webp'
    },
    {
        name: 'project-6-0',
        type: 'texture',
        path: 'textures/Projects/6/project-6-0.webp'
    },
    {
        name: 'project-6-1',
        type: 'texture',
        path: 'textures/Projects/6/project-6-1.webp'
    },
    {
        name: 'project-6-2',
        type: 'texture',
        path: 'textures/Projects/6/project-6-2.webp'
    },
    {
        name: 'project-7-0',
        type: 'texture',
        path: 'textures/Projects/7/project-7-0.webp'
    },
    {
        name: 'project-7-1',
        type: 'texture',
        path: 'textures/Projects/7/project-7-1.webp'
    },
    {
        name: 'project-7-2',
        type: 'texture',
        path: 'textures/Projects/7/project-7-2.webp'
    },
    {
        name: 'project-8-0',
        type: 'texture',
        path: 'textures/Projects/8/project-8-0.webp'
    },
    {
        name: 'project-8-1',
        type: 'texture',
        path: 'textures/Projects/8/project-8-1.webp'
    },
    {
        name: 'project-8-2',
        type: 'texture',
        path: 'textures/Projects/8/project-8-2.webp'
    },
    {
        name: 'project-8-3',
        type: 'texture',
        path: 'textures/Projects/8/project-8-3.webp'
    },
    {
        name: 'project-9-0',
        type: 'texture',
        path: 'textures/Projects/9/project-9-0.webp'
    },
    {
        name: 'project-9-1',
        type: 'texture',
        path: 'textures/Projects/9/project-9-1.webp'
    },
    {
        name: 'project-9-2',
        type: 'texture',
        path: 'textures/Projects/9/project-9-2.webp'
    },
    {
        name: 'project-10-0',
        type: 'texture',
        path: 'textures/Projects/10/project-10-0.webp'
    },
    {
        name: 'project-10-1',
        type: 'texture',
        path: 'textures/Projects/10/project-10-1.webp'
    },
    {
        name: 'project-10-2',
        type: 'texture',
        path: 'textures/Projects/10/project-10-2.webp'
    },
    {
        name: 'project-10-3',
        type: 'texture',
        path: 'textures/Projects/10/project-10-3.webp'
    },
    {
        name: 'project-11-0',
        type: 'texture',
        path: 'textures/Projects/11/project-11-0.webp'
    },
    {
        name: 'project-11-1',
        type: 'texture',
        path: 'textures/Projects/11/project-11-1.webp'
    },
    {
        name: 'project-11-2',
        type: 'texture',
        path: 'textures/Projects/11/project-11-2.webp'
    },
    {
        name: 'project-12-0',
        type: 'texture',
        path: 'textures/Projects/12/project-12-0.webp'
    },
    {
        name: 'project-12-1',
        type: 'texture',
        path: 'textures/Projects/12/project-12-1.webp'
    },
    {
        name: 'project-12-2',
        type: 'texture',
        path: 'textures/Projects/12/project-12-2.webp'
    },
    {
        name: 'project-13-0',
        type: 'texture',
        path: 'textures/Projects/13/project-13-0.webp'
    },
    {
        name: 'project-13-1',
        type: 'texture',
        path: 'textures/Projects/13/project-13-1.webp'
    },
    {
        name: 'project-13-2',
        type: 'texture',
        path: 'textures/Projects/13/project-13-2.webp'
    }
]