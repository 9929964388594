import * as THREE from 'three'

import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Mobile from './Mobile/Mobile.js'
import Resources from './Utils/Resources.js'

import sources from './sources.js'

let instance = null

export default class Experience
{
    constructor(_canvas, _isMobile)
    {
        // Singleton
        if(instance)
        {
            return instance
        }
        instance = this
        
        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas

        // isMobile
        this.isMobile = _isMobile

        if(this.isMobile)
        {
            document.querySelector('link#desktop').remove()

            document.querySelector('.desktop').remove()

            this.sizes = new Sizes()
            this.scene = new THREE.Scene()
            this.resources = new Resources(sources)
            this.camera = new Camera()
            this.renderer = new Renderer()
            this.mobile = new Mobile()
        }
        else
        {
            document.querySelector('link#mobile').remove()

            document.querySelector('.mobile').remove()

            this.sizes = new Sizes()
            this.scene = new THREE.Scene()
            this.resources = new Resources(sources)
            this.camera = new Camera()
            this.renderer = new Renderer()
            this.world = new World()
    
            // Resize event
            this.sizes.on('resize', () =>
            {
                this.resize()
            })
        }

        // Setup
        this.time = new Time()

        // Time tick event
        this.time.on('tick', () =>
        {
            this.update()
        })
    }

    resize()
    {
        this.camera.resize()
        this.renderer.resize()
        this.world.resize()
    }

    update()
    {
        if(this.camera)
        {
            this.camera.update()
        }

        if(this.world)
        {
            this.world.update()
        }

        if(this.mobile)
        {
            this.mobile.update()
        }

        if(this.renderer)
        {
            this.renderer.update()
        }
    }

    destroy()
    {
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()
    }
}